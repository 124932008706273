import { FC, HTMLAttributes } from 'react';

type AvastProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Avast: FC<AvastProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <circle cx="512" cy="512" r="512" fill="#ff7800"></circle>
      <path
        fill="#fff"
        d="M669.73 256a85.5 85.5 0 0 0-17.61 1.41c-15.05 2.76-27.67 9-40.47 20.35-13.11 11.69-16.45 23.69-31.47 38.37-23.69 23.17-52 34.52-85.66 32.24-16.48-1.12-30.37-5-46.92-14.35-17.82-10-24.74-22.05-38.46-25.19-14.26-3.24-30.1.72-39.59 11.62-13 14.86-11.26 28.69-5.63 46.48 5.32 16.8 9.89 33.09 10.75 50.85a155.4 155.4 0 0 1-6.14 51.8c-9.08 30.63-31.46 59.37-52.9 78.31C298 563.53 285.48 566 274.15 577A57.6 57.6 0 0 0 256 618.62 58.33 58.33 0 0 0 303.58 676c21.19 3.92 41.4-5.72 47.71-7.7 44-13.83 99.24-12.13 139.17-.51 33 9.61 61.57 26.64 88.22 48.18 12.8 10.36 22.52 24.3 36 33.91 34.64 24.71 94.46 29.48 132.75-19.77 25.87-33.37 32.11-90.92-9.52-130-16.72-15.71-27.81-17.92-41.36-27.84-41.5-30.36-51.5-94-19.66-135.43 9.11-11.86 20.44-20.2 33.21-28.65 13.24-8.79 25.32-21.54 31.6-34.79 12.56-26.61 9.59-60.25-9.93-85.4-15.08-19.41-37.17-31.28-62-31.91zm-96.38 164.16c7.61 0 17.31 6.26 17.31 17.58v133.38c0 6.88-5.19 17.55-17.48 17.55a17.38 17.38 0 0 1-17.71-17v-14.73c-11.29 15.57-29.55 29.93-55.89 29.93q-30.15 0-51.37-24.33T427 504.22q0-34.25 21.22-58.44t51.37-24.19c24.57 0 42.58 11.89 55.89 29.78V437.7c0-7.36 5-17.55 17.89-17.55zM509.22 453c-26.34 0-46.27 22.86-46.27 50.74 0 26.38 19.38 50.88 46.27 50.88 27.23 0 46.28-24.1 46.28-50.88 0-25.32-17.88-50.74-46.28-50.74"
      ></path>
    </svg>
  );
};

export default Avast;
