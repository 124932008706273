import { FC, HTMLAttributes } from 'react';

type FirefoxProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Firefox: FC<FirefoxProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="fi_5968827"
      width={size}
      height={size}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 512.01 512.01"
      {...props}
    >
      <linearGradient
        id="shape-base_00000027569791829998962360000016697435614286852497_"
        x1="-678.691"
        x2="-679.495"
        y1="6127.485"
        y2="6126.684"
        gradientTransform="matrix(480.0036 0 0 -495.9987 326214.75 3039304)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#FFF339"></stop>
        <stop offset="0.37" stopColor="#FF9800"></stop>
        <stop offset="0.53" stopColor="#FF3346"></stop>
        <stop offset="0.7" stopColor="#FF0D8A"></stop>
      </linearGradient>
      <path
        id="shape-base"
        fill="url(#shape-base_00000027569791829998962360000016697435614286852497_)"
        d="M485.903 171.728c-10.782-25.935-32.619-53.934-49.767-62.783 13.956 27.36 22.034 54.806 25.12 75.289 0 .04.016.14.049.413-28.052-69.918-75.617-98.111-114.458-159.498-1.961-3.104-3.928-6.216-5.844-9.497a76 76 0 0 1-2.731-5.132 45 45 0 0 1-3.709-9.813.647.647 0 0 0-.564-.665.84.84 0 0 0-.466 0c-.035.012-.087.053-.124.067-.055.022-.429.244-.412.224-62.318 36.497-83.125 103.75-85.068 137.529a124.13 124.13 0 0 0-68.276 26.31 74 74 0 0 0-6.426-4.852 114.93 114.93 0 0 1-.696-60.627c-25.484 11.604-45.306 29.946-59.716 46.142h-.115c-9.834-12.456-9.141-53.546-8.58-62.125-.118-.532-7.336 3.747-8.282 4.392a180.7 180.7 0 0 0-24.244 20.769 217 217 0 0 0-23.166 27.781c0 .012-.007.027-.011.039 0-.013.007-.027.011-.039a209.4 209.4 0 0 0-33.287 75.154c-.119.538-.219 1.095-.334 1.637-.467 2.184-2.147 13.11-2.441 15.484-.023.183-.033.358-.055.541a236.5 236.5 0 0 0-4.026 34.227c0 .423-.026.842-.026 1.267.006 136.995 111.077 248.048 248.083 248.048 122.7 0 224.579-89.072 244.524-206.07.42-3.175.757-6.367 1.127-9.57 4.931-42.54-.547-87.252-16.09-124.642M199.972 365.89c1.16.554 2.25 1.16 3.442 1.692.05.034.118.072.168.103a128 128 0 0 1-3.61-1.795m261.352-181.182-.033-.241c.012.088.028.18.041.267z"
      ></path>
      <radialGradient
        id="body-outer-ring_00000023282595785137162540000011968843662847919548_"
        cx="-678.333"
        cy="6130.64"
        r="1.321"
        gradientTransform="matrix(384.0014 0 0 -390.468 260923.64 2393927.75)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stopColor="#FFBD44"></stop>
        <stop offset="0.28" stopColor="#FF9800"></stop>
        <stop offset="0.47" stopColor="#FF3450"></stop>
        <stop offset="0.78" stopColor="#FF027B"></stop>
        <stop offset="0.86" stopColor="#FF0083"></stop>
      </radialGradient>
      <path
        id="body-outer-ring"
        fill="url(#body-outer-ring_00000023282595785137162540000011968843662847919548_)"
        d="M485.903 171.728c-10.782-25.935-32.619-53.934-49.767-62.783 13.956 27.36 22.034 54.806 25.12 75.289 0-.06.011.05.037.233.012.088.028.18.041.267 23.406 63.453 10.655 127.978-7.72 167.407-28.427 61.007-97.254 123.531-204.982 120.481-116.393-3.295-218.932-89.657-238.073-202.77-3.488-17.837 0-26.894 1.755-41.377-2.138 11.165-2.952 14.39-4.026 34.227 0 .423-.026.842-.026 1.267.003 136.988 111.074 248.041 248.08 248.041 122.7 0 224.579-89.072 244.524-206.07.42-3.175.757-6.367 1.127-9.57 4.931-42.54-.547-87.252-16.09-124.642"
      ></path>
      <radialGradient
        id="body-outer-ring-shadow_00000096046357773761222090000015547138260334618799_"
        cx="-678.775"
        cy="6130.098"
        r="1.321"
        gradientTransform="matrix(393.6014 0 0 -390.468 267417.781 2393877.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stopColor="#AF0611"></stop>
        <stop offset="0.35" stopColor="#CE1223" stopOpacity="0.74"></stop>
        <stop offset="0.43" stopColor="#FD243B" stopOpacity="0.34"></stop>
        <stop offset="0.5" stopColor="#FF304B" stopOpacity="0.09"></stop>
        <stop offset="0.53" stopColor="#FF3450" stopOpacity="0"></stop>
      </radialGradient>
      <path
        id="body-outer-ring-shadow"
        fill="url(#body-outer-ring-shadow_00000096046357773761222090000015547138260334618799_)"
        d="M485.903 171.728c-10.782-25.935-32.619-53.934-49.767-62.783 13.956 27.36 22.034 54.806 25.12 75.289 0-.06.011.05.037.233.012.088.028.18.041.267 23.406 63.453 10.655 127.978-7.72 167.407-28.427 61.007-97.254 123.531-204.982 120.481-116.393-3.295-218.932-89.657-238.073-202.77-3.488-17.837 0-26.894 1.755-41.377-2.138 11.165-2.952 14.39-4.026 34.227 0 .423-.026.842-.026 1.267.003 136.988 111.074 248.041 248.08 248.041 122.7 0 224.579-89.072 244.524-206.07.42-3.175.757-6.367 1.127-9.57 4.931-42.54-.547-87.252-16.09-124.642"
      ></path>
      <radialGradient
        id="tail-tip_00000033348937748295745630000000484682573222251967_"
        cx="-674.593"
        cy="6144.096"
        r="1.445"
        gradientTransform="matrix(115.236 0 0 -195.642 78042.39 1202019.375)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stopColor="#FFF339"></stop>
        <stop offset="0.53" stopColor="#FF9800"></stop>
      </radialGradient>
      <path
        id="tail-tip"
        fill="url(#tail-tip_00000033348937748295745630000000484682573222251967_)"
        d="M365.346 200.886c.541.38 1.032.758 1.541 1.135a135 135 0 0 0-23.03-30.051C266.743 94.865 333.043.322 333.061.302c-62.318 36.497-83.184 103.778-85.127 137.556 2.89-.2 5.773-.443 8.714-.443 46.504.001 87.008 25.57 108.698 63.471"
      ></path>
      <radialGradient
        id="globe-base_00000080184773766387035040000015885096926909371788_"
        cx="-677.586"
        cy="6138.399"
        r="1.032"
        gradientTransform="matrix(213.483 0 0 -233.9799 144848.297 1436665.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.35" stopColor="#008EE9"></stop>
        <stop offset="0.67" stopColor="#A059FF"></stop>
        <stop offset="1" stopColor="#DF36EA"></stop>
      </radialGradient>
      <path
        id="globe-base"
        fill="url(#globe-base_00000080184773766387035040000015885096926909371788_)"
        d="M256.804 216.282c-.406 6.171-22.208 27.451-29.832 27.451-70.544 0-81.995 42.669-81.995 42.669 3.125 35.933 28.14 65.523 58.437 81.18 1.382.714 2.781 1.359 4.181 1.997q3.6 1.594 7.287 2.979a110.2 110.2 0 0 0 32.28 6.226c123.644 5.799 147.599-147.814 58.369-192.422 22.851-3.974 46.57 5.216 59.816 14.521-21.69-37.898-62.194-63.468-108.697-63.468-2.942 0-5.823.243-8.714.443a124.13 124.13 0 0 0-68.276 26.31c3.782 3.2 8.052 7.478 17.047 16.339 16.828 16.585 60.005 33.76 60.097 35.775"
      ></path>
      <radialGradient
        id="globe-shadow_00000116931814237368977270000009253733651089027476_"
        cx="-676.932"
        cy="6139.196"
        r="0.609"
        gradientTransform="matrix(192.1347 0 0 -233.9799 130310.273 1436665.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21" stopColor="#A059FF" stopOpacity="0"></stop>
        <stop offset="0.97" stopColor="#80008E" stopOpacity="0.6"></stop>
      </radialGradient>
      <path
        id="globe-shadow"
        fill="url(#globe-shadow_00000116931814237368977270000009253733651089027476_)"
        d="M256.804 216.282c-.406 6.171-22.208 27.451-29.832 27.451-70.544 0-81.995 42.669-81.995 42.669 3.125 35.933 28.14 65.523 58.437 81.18 1.382.714 2.781 1.359 4.181 1.997q3.6 1.594 7.287 2.979a110.2 110.2 0 0 0 32.28 6.226c123.644 5.799 147.599-147.814 58.369-192.422 22.851-3.974 46.57 5.216 59.816 14.521-21.69-37.898-62.194-63.468-108.697-63.468-2.942 0-5.823.243-8.714.443a124.13 124.13 0 0 0-68.276 26.31c3.782 3.2 8.052 7.478 17.047 16.339 16.828 16.585 60.005 33.76 60.097 35.775"
      ></path>
      <radialGradient
        id="ear_00000140711879134547457310000011691267121237655174_"
        cx="-667.268"
        cy="6207.806"
        r="2.994"
        gradientTransform="matrix(58.524 0 0 -58.732 39290.89 364634.906)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="#FFE200"></stop>
        <stop offset="0.79" stopColor="#FF7232"></stop>
      </radialGradient>
      <path
        id="ear"
        fill="url(#ear_00000140711879134547457310000011691267121237655174_)"
        d="M168.088 155.917a255 255 0 0 1 5.142 3.406 114.93 114.93 0 0 1-.696-60.627c-25.484 11.604-45.306 29.946-59.716 46.142 1.192-.033 37.176-.681 55.27 11.079"
      ></path>
      <radialGradient
        id="body_00000155128087859778917270000012667265649586154429_"
        cx="-678.632"
        cy="6131.385"
        r="1.858"
        gradientTransform="matrix(450.1546 0 0 -377.8801 305889 2316851.25)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFF339"></stop>
        <stop offset="0.46" stopColor="#FF9800"></stop>
        <stop offset="0.72" stopColor="#FF3346"></stop>
        <stop offset="0.9" stopColor="#FF0D8A"></stop>
      </radialGradient>
      <path
        id="body"
        fill="url(#body_00000155128087859778917270000012667265649586154429_)"
        d="M10.559 269.852C29.7 382.965 132.239 469.327 248.632 472.623c107.728 3.049 176.555-59.479 204.982-120.481 18.374-39.432 31.127-103.949 7.72-167.407l-.008-.027-.033-.24c-.026-.184-.041-.293-.037-.233 0 .04.016.14.049.413 8.799 57.457-20.428 113.119-66.117 150.756l-.137.323c-89.027 72.488-174.219 43.735-191.468 31.965a148 148 0 0 1-3.613-1.799c-51.904-24.804-73.346-72.091-68.749-112.644-43.827 0-58.771-36.964-58.771-36.964s39.349-28.056 91.209-3.655c48.031 22.599 93.139 3.657 93.144 3.655-.092-2.015-43.269-19.19-60.101-35.774-8.994-8.861-13.265-13.133-17.047-16.339a74 74 0 0 0-6.426-4.852 288 288 0 0 0-5.142-3.406c-18.093-11.76-54.078-11.112-55.27-11.081h-.115c-9.834-12.456-9.141-53.546-8.58-62.126-.118-.532-7.336 3.747-8.282 4.392a181 181 0 0 0-24.244 20.769 217 217 0 0 0-23.166 27.787c0 .012-.007.027-.011.039 0-.013.007-.027.011-.039a209.4 209.4 0 0 0-33.287 75.154c-.119.536-8.93 39.061-4.584 59.043"
      ></path>
      <radialGradient
        id="tail-flame_00000039826427530162296890000015335833112349427105_"
        cx="-714.737"
        cy="6125.221"
        r="3.601"
        gradientTransform="matrix(16.0845 151.871 153.0334 -15.9623 -925538.25 206256.938)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#FFF339"></stop>
        <stop offset="0.3" stopColor="#FF9800"></stop>
        <stop offset="0.57" stopColor="#FF3346"></stop>
        <stop offset="0.74" stopColor="#FF0D8A"></stop>
      </radialGradient>
      <path
        id="tail-flame"
        fill="url(#tail-flame_00000039826427530162296890000015335833112349427105_)"
        d="M343.858 171.97a135 135 0 0 1 23.03 30.051c1.366 1.026 2.642 2.049 3.724 3.047 56.24 51.819 26.773 125.11 24.575 130.333 45.689-37.636 74.916-93.299 66.117-150.756-28.052-69.917-75.617-98.11-114.458-159.497-1.961-3.104-3.928-6.216-5.844-9.497a76 76 0 0 1-2.731-5.132 45 45 0 0 1-3.709-9.813.647.647 0 0 0-.564-.665c-.152-.044-.241-.061-.394-.017-.035.012-.159.07-.196.084-.055.022-.123.073-.182.103-9.446 4.862-66.483 94.654 10.632 171.759"
      ></path>
      <radialGradient
        id="tail-small_00000004548343451468281620000017141347834490497209_"
        cx="-677.317"
        cy="6144.934"
        r="2.461"
        gradientTransform="matrix(195.1418 0 0 -191.7138 132414.375 1178169.875)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.14" stopColor="#FFF339"></stop>
        <stop offset="0.48" stopColor="#FF9800"></stop>
        <stop offset="0.66" stopColor="#FF3346"></stop>
        <stop offset="0.9" stopColor="#FF0D8A"></stop>
      </radialGradient>
      <path
        id="tail-small"
        fill="url(#tail-small_00000004548343451468281620000017141347834490497209_)"
        d="M370.612 205.068c-1.083-.998-2.359-2.021-3.724-3.047-.505-.38-1-.758-1.541-1.135-13.246-9.305-36.965-18.495-59.816-14.521 89.23 44.608 65.275 198.221-58.369 192.422a110.2 110.2 0 0 1-32.28-6.226 139 139 0 0 1-7.287-2.979c-1.4-.638-2.798-1.283-4.181-1.997.05.034.118.072.168.103 17.249 11.768 102.441 40.523 191.468-31.965l.137-.323c2.198-5.218 31.664-78.511-24.575-130.332"
      ></path>
      <radialGradient
        id="cheek-tufts_00000087374028332401807970000013052991941015548819_"
        cx="-675.571"
        cy="6150.447"
        r="3.232"
        gradientTransform="matrix(178.5936 0 0 -159.7733 121018.773 982806.125)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09" stopColor="#FFF339"></stop>
        <stop offset="0.63" stopColor="#FF9800"></stop>
      </radialGradient>
      <path
        id="cheek-tufts"
        fill="url(#cheek-tufts_00000087374028332401807970000013052991941015548819_)"
        d="M144.976 286.402s11.451-42.669 81.995-42.669c7.626 0 29.429-21.28 29.832-27.451s-45.11 18.944-93.144-3.655c-51.86-24.401-91.209 3.655-91.209 3.655s14.944 36.964 58.771 36.964c-4.597 40.553 16.845 87.845 68.749 112.644 1.16.554 2.25 1.16 3.441 1.692-30.294-15.657-55.31-45.247-58.435-81.18"
      ></path>
      <linearGradient
        id="overlay-yellow_00000062876945044336489620000004081868831753027202_"
        x1="-678.742"
        x2="-679.382"
        y1="6127.48"
        y2="6126.757"
        gradientTransform="matrix(479.9996 0 0 -495.9987 326212 3039304)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.17" stopColor="#FFF339" stopOpacity="0.8"></stop>
        <stop offset="0.6" stopColor="#FFF339" stopOpacity="0"></stop>
      </linearGradient>
      <path
        id="overlay-yellow"
        fill="url(#overlay-yellow_00000062876945044336489620000004081868831753027202_)"
        d="M485.903 171.728c-10.782-25.935-32.619-53.934-49.767-62.783 13.956 27.36 22.034 54.806 25.12 75.289 0 .04.016.14.049.413-28.052-69.918-75.617-98.111-114.458-159.498-1.961-3.104-3.928-6.216-5.844-9.497a76 76 0 0 1-2.731-5.132 45 45 0 0 1-3.709-9.813.647.647 0 0 0-.564-.665c-.152-.044-.241-.061-.394-.017-.035.012-.159.07-.196.084a4 4 0 0 0-.276.149c-64.085 38.36-83.261 103.826-85.204 137.604 2.89-.2 5.772-.443 8.714-.443 46.506 0 87.01 25.569 108.697 63.468-13.246-9.305-36.965-18.495-59.816-14.521 89.23 44.608 65.275 198.221-58.369 192.422a110.2 110.2 0 0 1-32.28-6.225 139 139 0 0 1-7.287-2.979c-1.4-.638-2.798-1.283-4.181-1.997.05.034.118.072.168.103a148 148 0 0 1-3.613-1.799c1.16.554 2.25 1.16 3.442 1.692-30.297-15.657-55.313-45.247-58.437-81.18 0 0 11.451-42.669 81.995-42.669 7.626 0 29.429-21.28 29.832-27.451-.092-2.015-43.269-19.19-60.101-35.774-8.994-8.861-13.265-13.133-17.047-16.339a74 74 0 0 0-6.426-4.852 114.93 114.93 0 0 1-.696-60.627c-25.484 11.604-45.306 29.946-59.716 46.142h-.115c-9.834-12.456-9.141-53.546-8.58-62.126-.118-.532-7.336 3.747-8.282 4.392a180.7 180.7 0 0 0-24.244 20.769 217.3 217.3 0 0 0-23.159 27.784c0 .012-.007.027-.011.039 0-.013.007-.027.011-.039a209.4 209.4 0 0 0-33.287 75.154c-.119.538-.219 1.095-.334 1.637-.467 2.184-2.566 13.268-2.859 15.645-.023.183.022-.182 0 0A289 289 0 0 0 8.29 262.7c0 .423-.026.842-.026 1.267 0 136.989 111.071 248.042 248.077 248.042 122.7 0 224.579-89.072 244.524-206.07.42-3.175.757-6.367 1.127-9.57 4.932-42.539-.546-87.251-16.089-124.641m-24.61 12.739c.012.088.028.18.041.267l-.008-.027z"
      ></path>
    </svg>
  );
};

export default Firefox;
