import { FC, HTMLAttributes } from 'react';

type HarmonyProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Harmony: FC<HarmonyProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...props}
    >
      <path d="M1.861 0H3.59v3.548h3.861V0H9.19v8.883H7.458V5.136H3.59v3.746H1.858Zm11.993 0h1.706l2.809 4.7h.1L21.278 0h1.719v8.883h-1.719v-4.38l.1-1.489h-.1l-2.334 3.983h-1.039l-2.347-3.983h-.1l.1 1.489v4.38h-1.706Zm4.702 21.648a4 4 0 0 1-1.154-.161 3.4 3.4 0 0 1-1.01-.484 3.5 3.5 0 0 1-.8-.782 3.8 3.8 0 0 1-.538-1.092l1.666-.62a2.4 2.4 0 0 0 .643 1.116 1.68 1.68 0 0 0 1.207.434 2.2 2.2 0 0 0 .524-.062 1.8 1.8 0 0 0 .459-.2 1 1 0 0 0 .328-.335.9.9 0 0 0 .118-.459 1.05 1.05 0 0 0-.092-.447 1 1 0 0 0-.315-.373 2.5 2.5 0 0 0-.564-.335 8 8 0 0 0-.852-.335l-.577-.2a5 5 0 0 1-.774-.335 3.4 3.4 0 0 1-.7-.509 2.7 2.7 0 0 1-.525-.695 2.1 2.1 0 0 1-.2-.918 2.25 2.25 0 0 1 .21-.968 2.4 2.4 0 0 1 .616-.794 2.9 2.9 0 0 1 .957-.533 3.7 3.7 0 0 1 1.246-.2 3.6 3.6 0 0 1 1.22.186 2.8 2.8 0 0 1 .879.459 2.5 2.5 0 0 1 .59.608 3 3 0 0 1 .328.633l-1.56.62a1.55 1.55 0 0 0-.485-.67 1.4 1.4 0 0 0-.944-.3 1.66 1.66 0 0 0-.957.261.75.75 0 0 0-.38.658.84.84 0 0 0 .367.682 4.2 4.2 0 0 0 1.167.534l.59.186a6.3 6.3 0 0 1 1.023.434 3 3 0 0 1 .8.57 2.2 2.2 0 0 1 .511.769 2.4 2.4 0 0 1 .183.98 2.3 2.3 0 0 1-.3 1.2 2.6 2.6 0 0 1-.747.819 3.4 3.4 0 0 1-1.036.484 4.2 4.2 0 0 1-1.128.161Zm-13.028 0a4.44 4.44 0 0 1-3.23-1.34 4.8 4.8 0 0 1-.956-1.476 4.9 4.9 0 0 1-.339-1.824 4.8 4.8 0 0 1 .339-1.811 4.6 4.6 0 0 1 .956-1.477 4.4 4.4 0 0 1 1.427-.992 4.5 4.5 0 0 1 1.8-.36 4.4 4.4 0 0 1 1.79.36 4.3 4.3 0 0 1 1.44.992 4.4 4.4 0 0 1 .944 1.477 4.7 4.7 0 0 1 .351 1.811 4.8 4.8 0 0 1-.351 1.824 4.6 4.6 0 0 1-.944 1.476 4.5 4.5 0 0 1-3.23 1.34Zm0-1.588a2.8 2.8 0 0 0 1.125-.223 2.8 2.8 0 0 0 .92-.621 2.7 2.7 0 0 0 .617-.955 3.3 3.3 0 0 0 .23-1.253 3.2 3.2 0 0 0-.23-1.24 2.7 2.7 0 0 0-.617-.968 2.8 2.8 0 0 0-.92-.62 2.8 2.8 0 0 0-1.125-.223 2.86 2.86 0 0 0-2.057.844 2.95 2.95 0 0 0-.617.968 3.4 3.4 0 0 0-.218 1.24 3.5 3.5 0 0 0 .218 1.253 3 3 0 0 0 .617.955 2.86 2.86 0 0 0 2.057.843m-3.297 2.428h6.5V24h-6.5Z"></path>
    </svg>
  );
};

export default Harmony;
