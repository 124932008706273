import { FC, HTMLAttributes } from 'react';

type LinuxProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Linux: FC<LinuxProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...props}
    >
      <linearGradient id="lg1">
        <stop offset="0" stopOpacity="0.1"></stop>
        <stop offset="1" stopOpacity="0"></stop>
      </linearGradient>
      <linearGradient
        xlinkHref="#lg1"
        id="SVGID_Linux_1_"
        x1="10.383"
        x2="17.165"
        y1="6.81"
        y2="13.591"
        gradientUnits="userSpaceOnUse"
      ></linearGradient>
      <linearGradient
        xlinkHref="#lg1"
        id="SVGID_Linux_2_"
        x1="17.862"
        x2="21.759"
        y1="17.22"
        y2="21.117"
        gradientUnits="userSpaceOnUse"
      ></linearGradient>
      <linearGradient
        id="SVGID_Linux_3_"
        x1="5.245"
        x2="23.086"
        y1="10.06"
        y2="18.379"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" stopOpacity="0.2"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
      </linearGradient>
      <path
        fill="#1a1a1a"
        d="M19.681 18.034C19.9 17.565 20 16.909 20 16c0-3.247-1.496-5.12-2.697-6.625C16.575 8.463 16 7.743 16 7l.001-.233c.008-1.38.024-4.253-1.502-5.788C13.853.329 13.012 0 12 0 9.9 0 8.5 1.02 8.5 6c0 1.37-.577 2.493-1.187 3.682-.268.52-.544 1.06-.776 1.63C6.075 12.447 5 15.227 5 16.5a.5.5 0 0 0 .057.232l3.54 6.749a.5.5 0 0 0 .443.264.5.5 0 0 0 .377-.17C9.639 23.329 11.112 23 12 23h3.392a.5.5 0 0 0 .349-.142l4.108-4a.5.5 0 0 0-.168-.824"
      ></path>
      <path
        fill="#fff"
        d="M7.313 9.932C7.923 8.743 8.5 7.62 8.5 6.25c0-4.98 1.4-6 3.5-6 1.012 0 1.853.33 2.499.979 1.334 1.341 1.49 3.705 1.502 5.2.001-1.466-.087-4.026-1.502-5.45C13.853.329 13.012 0 12 0 9.9 0 8.5 1.02 8.5 6c0 1.37-.577 2.493-1.187 3.682-.268.52-.544 1.06-.776 1.63C6.075 12.447 5 15.227 5 16.5c0 .023.013.043.016.065.085-1.336 1.078-3.916 1.52-5.003.233-.57.51-1.11.777-1.63"
        opacity="0.2"
      ></path>
      <path
        fill="#0099d8"
        d="M19.858 18.401a.5.5 0 0 1 .118.222.49.49 0 0 0-.118-.472c-.03-.03-.068-.047-.103-.068-.025.067-.046.14-.074.2a.5.5 0 0 1 .177.118"
      ></path>
      <path
        fill="#fff"
        d="M16 7.25c0 .743.575 1.463 1.303 2.375 1.19 1.491 2.665 3.348 2.692 6.54 0-.057.005-.106.005-.165 0-3.247-1.496-5.12-2.697-6.625-.726-.91-1.3-1.628-1.302-2.37v.012z"
        opacity="0.2"
      ></path>
      <path fill="#0099d8" d="m16 7 .001.005q.002-.258 0-.575v.337z"></path>
      <path
        fill="#fff"
        d="M16.13 16.812c-.013-2.435-.343-5.174-2.267-9.106-.952.644-2.7 1.153-2.91 1.153-.379 0-1.205-.27-1.812-.806-.25.736-.596 1.418-.938 2.086a19 19 0 0 0-.74 1.55c-.604 1.484-1.294 3.44-1.433 4.478.423.282.717.892 1.043 1.573.338.706.72 1.507 1.213 1.85.901.628 1.369 1.523 1.491 2.336.343.035.776.064 1.267.064 1.22 0 2.874-.248 4.097-.99.069-1.323.301-3.444.988-4.188"
      ></path>
      <path
        fill="#0099d8"
        d="M19.995 16.164c-.012.82-.11 1.432-.314 1.87.027.01.049.033.074.049.163-.451.245-1.042.245-1.833 0-.03-.005-.056-.005-.086"
      ></path>
      <path
        d="M9.777 21.926c.343.035.776.064 1.267.064 1.22 0 2.874-.248 4.097-.99v-.25c-1.223.742-2.877.99-4.097.99-.491 0-1-.034-1.342-.068"
        opacity="0.1"
      ></path>
      <path
        d="M15.744 22.608a.5.5 0 0 1-.349.142h-3.392c-.888 0-2.36.328-2.583.576a.51.51 0 0 1-.437.165.5.5 0 0 1-.384-.26L5.06 16.482c-.027-.051-.033-.11-.04-.167-.005.06-.017.13-.017.185a.5.5 0 0 0 .057.232l3.54 6.749a.5.5 0 0 0 .443.264.5.5 0 0 0 .377-.17c.222-.247 1.695-.575 2.583-.575h3.392a.5.5 0 0 0 .349-.142l4.108-4a.5.5 0 0 0 .127-.485.5.5 0 0 1-.127.235z"
        opacity="0.3"
      ></path>
      <path
        fill="#fdb515"
        d="M20.926 19.074c-.207-.136-.421-.276-.572-.428-.089-.088-.048-.497-.027-.716.042-.422.085-.858-.2-1.17a.81.81 0 0 0-.627-.26c-.268 0-.463.212-.475.479l.003.021H17a.5.5 0 0 0-.5-.5c-1.483 0-1.5 5.445-1.506 5.564-.028.298-.093.998.355 1.49.27.296.657.446 1.151.446 1.298 0 1.927-.669 2.536-1.315q.152-.165.318-.331c.253-.254.749-.435 1.186-.594.718-.262 1.46-.533 1.46-1.26 0-.721-.595-1.112-1.074-1.426M8.286 19.59c-.493-.343-.875-1.144-1.213-1.85C6.627 16.807 6.241 16 5.5 16c-.936 0-1.14.84-1.25 1.292-.03.125-.076.312-.08.334-.074.046-.354.102-.538.138C3.022 17.885 2 18.087 2 19c0 .266.04.491.083.726.077.416.15.809-.067 1.649A.5.5 0 0 0 2 21.5c0 1.202 1.867 1.358 2.983 1.45.185.016.34.028.439.044.154.024.476.173.787.316.667.308 1.497.69 2.291.69.571 0 1.028-.37 1.223-.987.302-.964-.098-2.491-1.437-3.423"
      ></path>
      <path
        fill="#fff"
        d="M3.553 18.014c.191-.036.48-.092.557-.138.004-.022.052-.209.083-.334.113-.451.325-1.292 1.293-1.292.767 0 1.166.807 1.628 1.74.349.706.744 1.507 1.254 1.85.508.342.882.764 1.141 1.208a3.6 3.6 0 0 0-1.223-1.458c-.493-.343-.875-1.144-1.213-1.85C6.627 16.807 6.241 16 5.5 16c-.936 0-1.14.84-1.25 1.292-.03.125-.076.312-.08.334-.074.046-.354.102-.538.138-.53.105-1.369.273-1.58.913.33-.437 1.032-.573 1.501-.663"
        opacity="0.2"
      ></path>
      <path
        fill="#1a1a1a"
        d="M19.5 15.5a.5.5 0 0 0-.256.08l-3.339-5.463a27 27 0 0 0-1.46-3.346.5.5 0 0 0-.89.458c2.258 4.387 2.445 7.125 2.446 9.743-.006.105-.042 1.042.497 1.611.258.273.604.417 1.002.417l.176.001.17.001c.509 0 1.059-.039 1.489-.465.46-.456.665-1.239.665-2.537a.5.5 0 0 0-.5-.5"
      ></path>
      <path
        d="M19.335 18.287c-.43.426-.98.465-1.488.465l-.17-.001-.177-.001c-.398 0-.744-.144-1.002-.417-.403-.426-.485-1.058-.497-1.393v.032c-.006.105-.042 1.042.497 1.611.258.273.604.417 1.002.417l.176.001.17.001c.509 0 1.059-.039 1.489-.465.46-.456.665-1.239.665-2.537 0-.016-.008-.03-.01-.045-.022 1.167-.222 1.902-.655 2.332"
        opacity="0.3"
      ></path>
      <path
        fill="#fff"
        d="M13 3.042c-.58 0-1 .622-1 1.479S12.42 6 13 6s1-.622 1-1.479-.42-1.48-1-1.48"
      ></path>
      <ellipse cx="13" cy="4.888" rx="0.454" ry="0.888"></ellipse>
      <path
        fill="#fff"
        d="M10 3.042c-.58 0-1 .622-1 1.479S9.42 6 10 6s1-.622 1-1.479-.42-1.48-1-1.48"
      ></path>
      <path
        fill="url(#SVGID_Linux_1_)"
        d="M13.802 7.742c-.963.626-2.643 1.117-2.85 1.117-.378 0-1.204-.27-1.811-.806l6.781 6.781c-.16-1.936-.669-4.138-2.12-7.092"
      ></path>
      <ellipse cx="10" cy="4.871" rx="0.445" ry="0.871"></ellipse>
      <path
        fill="#fdb515"
        d="M11.598 5.01A.5.5 0 0 0 11.5 5c-1.075 0-3 1.008-3 2 0 .937 1.891 2 2.5 2 .295 0 3.5-1.113 3.5-2 0-1.308-2.221-1.854-2.902-1.99"
      ></path>
      <path
        fill="#fdb515"
        d="M11.598 5.01A.5.5 0 0 0 11.5 5c-1.075 0-3 1.008-3 2 0 .937 1.891 2 2.5 2 .295 0 3.5-1.113 3.5-2 0-1.308-2.221-1.854-2.902-1.99"
      ></path>
      <path
        d="M20.54 21.51c-.437.16-.933.34-1.186.594q-.165.166-.318.33c-.609.647-1.238 1.316-2.536 1.316-.494 0-.881-.15-1.151-.447-.294-.321-.36-.724-.367-1.054-.022.346-.01.892.367 1.304.27.297.657.447 1.151.447 1.298 0 1.927-.669 2.536-1.315q.152-.166.318-.331c.253-.254.749-.435 1.186-.594.718-.262 1.46-.533 1.46-1.26 0-.036-.017-.062-.02-.097-.1.61-.777.864-1.44 1.107M9.723 22.763c-.195.618-.652.987-1.223.987-.794 0-1.624-.382-2.29-.69-.312-.143-.634-.292-.788-.316-.1-.016-.254-.028-.44-.043-1.08-.09-2.85-.248-2.961-1.35l-.005.024A.5.5 0 0 0 2 21.5c0 1.202 1.867 1.358 2.983 1.45.185.016.34.028.439.044.154.024.476.173.787.316.667.308 1.497.69 2.291.69.571 0 1.028-.37 1.223-.987.079-.252.107-.544.085-.852-.005.214-.028.42-.085.602M10.99 8.75c-.561 0-2.172-.881-2.489-1.756L8.5 7c0 .937 1.891 2 2.5 2 .295 0 3.5-1.113 3.5-2 0-.02-.01-.035-.011-.054-.475.855-3.224 1.804-3.5 1.804"
        opacity="0.1"
      ></path>
      <path
        fill="url(#SVGID_Linux_2_)"
        d="M20.926 19.074c-.207-.136-.421-.276-.572-.428-.089-.088-.048-.497-.027-.716.042-.422.085-.858-.2-1.17a.6.6 0 0 0-.156-.114c-.063.91-.26 1.518-.636 1.89-.43.427-.98.466-1.488.466l-.17-.001L17.5 19c-.398 0-.744-.144-1.002-.417l3.42 3.421c.2-.09.417-.17.622-.244.718-.262 1.46-.533 1.46-1.26 0-.721-.595-1.112-1.074-1.426"
      ></path>
      <path
        fill="url(#SVGID_Linux_3_)"
        d="M20.926 19.074c-.207-.136-.421-.276-.572-.428-.089-.088-.048-.497-.027-.716.042-.422.085-.858-.2-1.17-.033-.038-.094-.075-.147-.112q.02-.3.02-.648c0-3.247-1.496-5.12-2.697-6.625C16.575 8.463 16 7.743 16 7l.001-.233c.008-1.38.024-4.253-1.502-5.788C13.853.329 13.012 0 12 0 9.9 0 8.5 1.02 8.5 6c0 1.37-.577 2.493-1.187 3.682-.268.52-.544 1.06-.776 1.63-.42 1.03-1.333 3.399-1.502 4.783-.54.228-.697.835-.785 1.197-.03.125-.076.312-.08.334-.074.046-.354.102-.538.138C3.022 17.885 2 18.087 2 19c0 .266.04.491.083.726.077.416.15.809-.067 1.649A.5.5 0 0 0 2 21.5c0 1.202 1.867 1.358 2.983 1.45.185.016.34.028.439.044.154.024.476.173.787.316.667.308 1.497.69 2.291.69.342 0 .634-.145.862-.384.017-.015.04-.023.055-.04C9.639 23.328 11.112 23 12 23h3.06c.056.193.135.385.289.553.27.297.657.447 1.151.447 1.298 0 1.927-.669 2.536-1.315q.152-.166.318-.331c.253-.254.749-.435 1.186-.594.718-.262 1.46-.533 1.46-1.26 0-.721-.595-1.112-1.074-1.426"
      ></path>
    </svg>
  );
};

export default Linux;
