import { FC, HTMLAttributes } from 'react';

type FluentWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const FluentWallet: FC<FluentWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="#fff"
        d="M48 0v7.722c0 8.89-7.14 16.112-16.002 16.255l-.269.003H24v7.742C24 40.867 16.74 48 7.795 48H0V22.76C0 10.19 10.2 0 22.78 0z"
      ></path>
      <path
        fill="#242265"
        fillOpacity="0.8"
        fillRule="evenodd"
        d="M37.696 0H48v7.722C48 16.867 40.74 24 31.795 24H24v-7.945C24 7.952 29.933 1.234 37.696 0"
        clipRule="evenodd"
      ></path>
      <path
        fill="#616EE1"
        fillOpacity="0.8"
        d="M48 0v7.722c0 8.89-7.14 16.112-16.002 16.255l-.269.003H24v7.742C24 40.867 16.74 48 7.795 48H0V22.76C0 10.19 10.2 0 22.78 0z"
      ></path>
    </svg>
  );
};

export default FluentWallet;
