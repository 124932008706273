import { FC, HTMLAttributes } from 'react';

type YandexProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Yandex: FC<YandexProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
    >
      <g fill="#FC3F1D">
        <path d="M215.371 512v-51.569c0-69.985-8.126-104.61-36.197-165.018L42.513 0h94.555l115.977 252.685c33.981 73.669 48.755 111.977 48.755 196.697V512zM270.774 247.528 378.626 0h90.861L360.158 247.528z"></path>
      </g>
    </svg>
  );
};

export default Yandex;
