import { FC, HTMLAttributes } from 'react';

type IEBrowserProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const IEBrowser: FC<IEBrowserProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#00BBEF"
        d="M511.848 268.66c-.582-40.739-10.545-79.091-28.76-112.644 79.09-177.354-82.685-152.943-93.47-150.99-33.553 6.711-67.107 17.496-95.867 31.157-110.247-1.318-206.113 57.52-237.27 179.75 57.52-64.711 98.263-91.074 122.23-100.66-5.033 4.554-10.066 9.347-15.099 14.14-38.347 38.347-69.503 76.693-95.867 117.437-20.372 35.95-43.14 67.107-55.123 107.851-64.71 234.873 139.007 136.609 167.767 119.833 31.157 15.339 64.71 23.727 103.057 23.727 100.66 0 186.94-64.709 218.096-153.386h-122.23c-57.52 91.073-194.13 50.329-198.923-47.934h330.74c1.246-9.827.82-21.21.719-28.281M44.498 474.773c-16.929-15.381-19.173-57.521 16.777-131.817 17.975 52.727 55.123 95.867 100.66 122.23-23.248 12.702-84.865 39.178-117.437 9.587m134.213-239.667c1.845-52.727 47.933-95.866 105.453-95.866s103.057 43.14 105.453 95.866zM364.453 52.959c21.33-10.306 75.901-31.079 105.453-11.983 22.194 14.342 31.812 42.465 7.19 105.453-26.363-43.14-64.71-76.693-112.643-93.47"
      ></path>
    </svg>
  );
};

export default IEBrowser;
