export type PlanType = 'free' | 'growth' | 'enterprise';

export const PLANS_NAMES: PlanType[] = ['free', 'growth', 'enterprise'];

export const PLAN_PADDLE_IDS = {
  growth: {
    monthly: [
      'pri_01jp4s41nq57hmxdaeeg75sqsm', // Newest ProductID always go first
      'pri_01j5x8qvh99fjepmchr3er964h', // Old ProductID
    ],
    annual: [
      'pri_01jp4s1rqdeg912afbdsgx5m21', // Newest ProductID always go first
      'pri_01j5x8trafv94rkmnbh5qmky9t', // Old ProductID
    ],
  },
  enterprise: {
    monthly: ['pri_01jmh29vzf3ee4tmwrmwcmhvv8'],
    annual: ['pri_01jmh2c20h4dyca9bcq7tz55ny'],
  },
};
