import { FC, HTMLAttributes } from 'react';

type SafariProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Safari: FC<SafariProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="fi_668290"
      x="0"
      y="0"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
    >
      <circle cx="256" cy="268.59" r="243.41" opacity="0.08"></circle>
      <linearGradient
        id="SVGID_Safari_1_"
        x1="256"
        x2="256"
        y1="10.4"
        y2="514"
        gradientTransform="matrix(1 0 0 -1 0 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#F2F2F2"></stop>
        <stop offset="1" stopColor="#FFF"></stop>
      </linearGradient>
      <circle
        cx="256"
        cy="251.8"
        r="251.8"
        fill="url(#SVGID_Safari_1_)"
      ></circle>
      <g opacity="0.08">
        <linearGradient
          id="SVGID_Safari_2_"
          x1="256"
          x2="256"
          y1="10.393"
          y2="514"
          gradientTransform="matrix(1 0 0 -1 0 514)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
          <stop offset="0.36" stopOpacity="0"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_Safari_2_)"
          d="M256 0C116.929 0 4.197 112.732 4.197 251.803S116.929 503.607 256 503.607s251.803-112.732 251.803-251.803S395.071 0 256 0m0 486.82c-129.586 0-235.016-105.43-235.016-235.016S126.414 16.787 256 16.787s235.016 105.43 235.016 235.016S385.586 486.82 256 486.82"
        ></path>
      </g>
      <path
        d="M256 0C116.929 0 4.197 112.732 4.197 251.803S116.929 503.607 256 503.607s251.803-112.732 251.803-251.803S395.071 0 256 0m0 495.213c-134.429 0-243.41-108.98-243.41-243.41S121.571 8.393 256 8.393s243.41 108.98 243.41 243.41S390.429 495.213 256 495.213"
        opacity="0.16"
      ></path>
      <linearGradient
        id="SVGID_Safari_3_"
        x1="256"
        x2="256"
        y1="35.58"
        y2="488.82"
        gradientTransform="matrix(1 0 0 -1 0 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3871E9"></stop>
        <stop offset="1" stopColor="#61C5F6"></stop>
      </linearGradient>
      <circle
        cx="256"
        cy="251.8"
        r="226.62"
        fill="url(#SVGID_Safari_3_)"
      ></circle>
      <g opacity="0.16">
        <linearGradient
          id="SVGID_Safari_4_"
          x1="256"
          x2="256"
          y1="35.574"
          y2="488.82"
          gradientTransform="matrix(1 0 0 -1 0 514)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0"></stop>
          <stop offset="0.36" stopOpacity="0"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_Safari_4_)"
          d="M256 25.18c-125.163 0-226.623 101.46-226.623 226.623S130.837 478.426 256 478.426s226.623-101.46 226.623-226.623S381.163 25.18 256 25.18m0 444.853c-120.521 0-218.229-97.708-218.229-218.229S135.479 33.574 256 33.574s218.229 97.708 218.229 218.229S376.521 470.033 256 470.033"
        ></path>
      </g>
      <g opacity="0.36">
        <path
          id="SVGCleanerId_0"
          fill="#FFF"
          d="M256 83.238a3.5 3.5 0 0 1-3.5-3.5V46.164a3.5 3.5 0 1 1 7 0v33.574a3.5 3.5 0 0 1-3.5 3.5"
        ></path>
        <g fill="#FFF">
          <path d="M231.344 68.063a3.5 3.5 0 0 1-3.466-3.043l-2.191-16.636a3.5 3.5 0 0 1 6.94-.914l2.191 16.636a3.5 3.5 0 0 1-3.474 3.957M183.734 80.83a3.5 3.5 0 0 1-3.235-2.161l-6.429-15.511a3.5 3.5 0 0 1 6.467-2.681l6.429 15.511a3.5 3.5 0 0 1-3.232 4.842M141.038 105.48a3.5 3.5 0 0 1-2.779-1.369l-10.223-13.32a3.499 3.499 0 1 1 5.553-4.261l10.223 13.32a3.499 3.499 0 0 1-2.774 5.63M106.175 140.338a3.48 3.48 0 0 1-2.128-.724l-13.321-10.223a3.499 3.499 0 1 1 4.261-5.553l13.321 10.223a3.499 3.499 0 0 1-2.133 6.277M81.524 183.037c-.446 0-.9-.086-1.339-.268l-15.511-6.429a3.5 3.5 0 1 1 2.681-6.467l15.511 6.429a3.5 3.5 0 0 1-1.342 6.735M68.764 230.652q-.228 0-.461-.03l-16.636-2.191a3.5 3.5 0 1 1 .914-6.94l16.636 2.191a3.5 3.5 0 0 1-.453 6.97M211.462 89.105a3.5 3.5 0 0 1-3.378-2.595l-8.687-32.432a3.5 3.5 0 0 1 6.762-1.812l8.687 32.432a3.5 3.5 0 0 1-3.384 4.407M169.971 106.287a3.5 3.5 0 0 1-3.035-1.75l-16.787-29.075a3.5 3.5 0 0 1 6.062-3.5l16.787 29.075a3.5 3.5 0 0 1-3.027 5.25M134.329 133.632a3.5 3.5 0 0 1-2.475-1.025l-23.737-23.736a3.5 3.5 0 1 1 4.95-4.95l23.737 23.736a3.5 3.5 0 0 1-2.475 5.975M106.979 169.271a3.5 3.5 0 0 1-1.747-.469l-29.075-16.787a3.5 3.5 0 1 1 3.5-6.062l29.075 16.787a3.5 3.5 0 0 1-1.753 6.531M89.803 210.769q-.45 0-.908-.12l-32.432-8.687a3.5 3.5 0 0 1 1.812-6.762l32.432 8.687a3.5 3.5 0 0 1-.904 6.882M83.935 255.303H50.361a3.5 3.5 0 1 1 0-7h33.574a3.5 3.5 0 1 1 0 7"></path>
        </g>
        <g fill="#FFF">
          <path d="M52.119 282.146a3.5 3.5 0 0 1-.453-6.97l16.636-2.19a3.5 3.5 0 0 1 .914 6.94l-16.636 2.19q-.233.03-.461.03M66.016 334.001a3.5 3.5 0 0 1-1.342-6.734l15.511-6.429a3.5 3.5 0 1 1 2.681 6.467l-15.511 6.429a3.5 3.5 0 0 1-1.339.267M92.859 380.491a3.499 3.499 0 0 1-2.133-6.276l13.321-10.223a3.497 3.497 0 0 1 4.907.646 3.5 3.5 0 0 1-.646 4.907l-13.321 10.223a3.48 3.48 0 0 1-2.128.723M130.81 418.446a3.499 3.499 0 0 1-2.774-5.631l10.223-13.32a3.5 3.5 0 0 1 5.553 4.261l-10.223 13.32c-.69.9-1.729 1.37-2.779 1.37M177.302 445.29a3.5 3.5 0 0 1-3.232-4.841l6.429-15.512a3.5 3.5 0 1 1 6.467 2.679l-6.429 15.512a3.5 3.5 0 0 1-3.235 2.162M229.162 459.18q-.228 0-.461-.03a3.5 3.5 0 0 1-3.013-3.927l2.191-16.636a3.501 3.501 0 0 1 6.94.914l-2.191 16.636a3.5 3.5 0 0 1-3.466 3.043M57.367 308.527a3.5 3.5 0 0 1-.904-6.882l32.432-8.688a3.501 3.501 0 0 1 1.812 6.762l-32.432 8.688q-.456.12-.908.12M77.911 358.124a3.5 3.5 0 0 1-1.754-6.532l29.075-16.787a3.5 3.5 0 0 1 3.5 6.062l-29.075 16.787a3.5 3.5 0 0 1-1.746.47M153.177 433.396a3.5 3.5 0 0 1-3.028-5.251l16.787-29.075a3.5 3.5 0 1 1 6.062 3.5l-16.787 29.075a3.5 3.5 0 0 1-3.034 1.751M202.779 453.936q-.451 0-.908-.12a3.5 3.5 0 0 1-2.475-4.286l8.687-32.433a3.5 3.5 0 0 1 6.762 1.81l-8.687 32.433a3.5 3.5 0 0 1-3.379 2.596M256 460.942a3.5 3.5 0 0 1-3.5-3.5v-33.573a3.5 3.5 0 1 1 7 0v33.573a3.5 3.5 0 0 1-3.5 3.5"></path>
        </g>
        <g fill="#FFF">
          <path d="M282.837 459.18a3.5 3.5 0 0 1-3.465-3.043l-2.19-16.636a3.5 3.5 0 0 1 3.013-3.927 3.497 3.497 0 0 1 3.927 3.013l2.19 16.636a3.5 3.5 0 0 1-3.475 3.957M334.698 445.29a3.5 3.5 0 0 1-3.234-2.161l-6.43-15.512a3.5 3.5 0 1 1 6.467-2.679l6.43 15.512a3.5 3.5 0 0 1-3.233 4.84M381.191 418.446a3.5 3.5 0 0 1-2.779-1.369l-10.224-13.32a3.499 3.499 0 1 1 5.553-4.261l10.224 13.32a3.499 3.499 0 0 1-2.774 5.63M419.141 380.491a3.48 3.48 0 0 1-2.128-.724l-13.32-10.223a3.5 3.5 0 0 1 4.261-5.553l13.32 10.223a3.5 3.5 0 0 1-2.133 6.277M445.984 334.001c-.447 0-.9-.086-1.339-.268l-15.511-6.429a3.5 3.5 0 0 1 2.679-6.467l15.511 6.429a3.5 3.5 0 0 1-1.34 6.735M459.882 282.146q-.229 0-.462-.03l-16.636-2.19a3.5 3.5 0 1 1 .914-6.94l16.636 2.19a3.5 3.5 0 0 1-.452 6.97M309.221 453.936a3.5 3.5 0 0 1-3.379-2.596l-8.688-32.433a3.5 3.5 0 1 1 6.762-1.81l8.688 32.433a3.5 3.5 0 0 1-3.383 4.406M358.822 433.396a3.5 3.5 0 0 1-3.034-1.751l-16.786-29.075a3.5 3.5 0 1 1 6.062-3.5l16.786 29.075a3.5 3.5 0 0 1-3.028 5.251M401.408 400.711a3.5 3.5 0 0 1-2.475-1.025l-23.737-23.736a3.499 3.499 0 1 1 4.949-4.949l23.737 23.736a3.499 3.499 0 0 1-2.474 5.974M434.089 358.124a3.5 3.5 0 0 1-1.747-.47l-29.074-16.787a3.5 3.5 0 1 1 3.5-6.062l29.074 16.787a3.5 3.5 0 0 1-1.753 6.532M454.633 308.527q-.451 0-.907-.12l-32.433-8.688a3.5 3.5 0 0 1 1.81-6.762l32.433 8.688a3.5 3.5 0 0 1-.903 6.882"></path>
        </g>
        <path
          id="SVGCleanerId_1"
          fill="#FFF"
          d="M461.64 255.303h-33.574a3.5 3.5 0 1 1 0-7h33.574a3.5 3.5 0 1 1 0 7"
        ></path>
        <path
          id="SVGCleanerId_1_1_"
          fill="#FFF"
          d="M461.64 255.303h-33.574a3.5 3.5 0 1 1 0-7h33.574a3.5 3.5 0 1 1 0 7"
        ></path>
        <g fill="#FFF">
          <path d="M443.236 230.652a3.5 3.5 0 0 1-.452-6.97l16.636-2.191a3.5 3.5 0 1 1 .914 6.94l-16.636 2.191q-.233.03-.462.03M430.476 183.037a3.5 3.5 0 0 1-1.34-6.735l15.511-6.429a3.5 3.5 0 1 1 2.679 6.467l-15.511 6.429a3.5 3.5 0 0 1-1.339.268M405.826 140.338a3.499 3.499 0 0 1-2.133-6.276l13.32-10.223a3.5 3.5 0 0 1 4.907.646 3.5 3.5 0 0 1-.646 4.907l-13.32 10.223a3.5 3.5 0 0 1-2.128.723M370.962 105.48a3.5 3.5 0 0 1-2.774-5.631l10.224-13.32a3.5 3.5 0 0 1 5.553 4.261l-10.224 13.32a3.49 3.49 0 0 1-2.779 1.37M328.267 80.83a3.5 3.5 0 0 1-3.233-4.842l6.43-15.511a3.5 3.5 0 0 1 6.467 2.681l-6.43 15.511a3.5 3.5 0 0 1-3.234 2.161M280.656 68.063q-.229 0-.462-.03a3.5 3.5 0 0 1-3.013-3.927l2.19-16.636a3.5 3.5 0 1 1 6.94.914l-2.19 16.636a3.5 3.5 0 0 1-3.465 3.043M422.196 210.769a3.5 3.5 0 0 1-.903-6.882l32.433-8.687a3.5 3.5 0 0 1 1.81 6.762l-32.433 8.687c-.302.08-.607.12-.907.12M405.021 169.271a3.5 3.5 0 0 1-1.753-6.531l29.074-16.787a3.5 3.5 0 0 1 3.5 6.062l-29.074 16.787a3.5 3.5 0 0 1-1.747.469M342.03 106.287a3.5 3.5 0 0 1-3.028-5.25l16.786-29.075a3.5 3.5 0 1 1 6.062 3.5l-16.786 29.075a3.5 3.5 0 0 1-3.034 1.75M300.537 89.105q-.451 0-.907-.12a3.5 3.5 0 0 1-2.476-4.287l8.688-32.432a3.5 3.5 0 0 1 6.762 1.812l-8.688 32.432a3.5 3.5 0 0 1-3.379 2.595"></path>
        </g>
        <path
          id="SVGCleanerId_0_1_"
          fill="#FFF"
          d="M256 83.238a3.5 3.5 0 0 1-3.5-3.5V46.164a3.5 3.5 0 1 1 7 0v33.574a3.5 3.5 0 0 1-3.5 3.5"
        ></path>
      </g>
      <path
        fill="#F4413D"
        d="M403.657 104.146 226.321 222.124 256 251.803z"
      ></path>
      <path
        fill="#CC2B2B"
        d="M403.657 104.146 256 251.803l29.679 29.679z"
      ></path>
      <path
        fill="#FFF"
        d="M226.321 222.124 108.343 399.461 256 251.803z"
      ></path>
      <path
        fill="#D8D8D8"
        d="m108.343 399.461 177.336-117.979L256 251.803z"
      ></path>
    </svg>
  );
};

export default Safari;
