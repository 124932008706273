import { Rdns } from '@formo/types';
import { ReactNode } from 'react';
import {
  BitgetWallet,
  BraveWallet,
  BybitWallet,
  Coin98Wallet,
  CoinbaseWallet,
  CompassWallet,
  CoreWallet,
  CryptoWallet,
  EnkryptWallet,
  ExodusWallet,
  FluentWallet,
  FrameWallet,
  FrontierWallet,
  GateWallet,
  HahaWallet,
  HotWallet,
  KeplrWallet,
  MagicEdenWallet,
  MetaMaskWallet,
  NestWallet,
  OkxWallet,
  OneKeyWallet,
  PhantomWallet,
  PlugWallet,
  RabbyWallet,
  RainbowWallet,
  RoninWallet,
  SafePalWallet,
  SeifWallet,
  SubWallet,
  TahoWallet,
  TalismanWallet,
  TokenPocketWallet,
  TrustWallet,
  UniWallet,
  XDeFiWallet,
  ZealWallet,
  ZerionWallet,
} from '~/components/icons/wallets';

type WalletMap = Record<Rdns, { rdns: string; name: string; icon: ReactNode }>;
// https://github.com/WalletConnect/EIP6963/blob/master/src/utils/constants.ts
// https://github.dev/rainbow-me/rainbowkit/tree/e8bcd6a3274e09fc8fb372927fa4a7da010e04b0/packages/rainbowkit/src/wallets/walletConnectors
export const WALLET_MAP: WalletMap = {
  'io.metamask': {
    name: 'MetaMask',
    rdns: 'io.metamask',
    icon: <MetaMaskWallet />,
  },
  'io.metamask.flask': {
    name: 'MetaMask Flask',
    rdns: 'io.metamask.flask',
    icon: <MetaMaskWallet />,
  },
  'io.metamask.mobile': {
    name: 'MetaMask Mobile',
    rdns: 'io.metamask.mobile',
    icon: <MetaMaskWallet />,
  },
  'me.rainbow': {
    name: 'Rainbow',
    rdns: 'me.rainbow',
    icon: <RainbowWallet />,
  },
  'app.phantom': {
    name: 'Phantom',
    rdns: 'app.phantom',
    icon: <PhantomWallet />,
  },
  'app.core.extension': {
    name: 'Core',
    rdns: 'app.core.extension',
    icon: <CoreWallet />,
  },
  'app.subwallet': {
    name: 'SubWallet',
    rdns: 'app.subwallet',
    icon: <SubWallet />,
  },
  'app.zeal': {
    name: 'Zeal',
    rdns: 'app.zeal',
    icon: <ZealWallet />,
  },
  'coin98.com': {
    name: 'Coin98',
    rdns: 'coin98.com',
    icon: <Coin98Wallet />,
  },
  'com.bitget.web3': {
    name: 'Bitget',
    rdns: 'com.bitget.web3',
    icon: <BitgetWallet />,
  },
  'com.crypto.wallet': {
    name: 'Crypto wallet',
    rdns: 'com.crypto.wallet',
    icon: <CryptoWallet />,
  },
  'com.brave.wallet': {
    name: 'Brave',
    rdns: 'com.brave.wallet',
    icon: <BraveWallet />,
  },
  'com.bybit': {
    name: 'Bybit',
    rdns: 'com.bybit',
    icon: <BybitWallet />,
  },
  'com.coinbase.wallet': {
    name: 'Coinbase',
    rdns: 'com.coinbase.wallet',
    icon: <CoinbaseWallet />,
  },
  'com.enkrypt': {
    name: 'Enkrypt',
    rdns: 'com.enkrypt',
    icon: <EnkryptWallet />,
  },
  'com.okex.wallet': {
    name: 'OKX',
    rdns: 'com.okex.wallet',
    icon: <OkxWallet />,
  },
  'com.passkeywallet.seif': {
    name: 'Seif',
    rdns: 'com.passkeywallet.seif',
    icon: <SeifWallet />,
  },
  'com.roninchain.wallet': {
    name: 'Ronin',
    rdns: 'com.roninchain.wallet',
    icon: <RoninWallet />,
  },
  'com.trustwallet.app': {
    name: 'Trust',
    rdns: 'com.trustwallet.app',
    icon: <TrustWallet />,
  },
  'io.gate.wallet': {
    name: 'Gate',
    rdns: 'io.gate.wallet',
    icon: <GateWallet />,
  },
  'io.leapwallet.CompassWallet': {
    name: 'Compass',
    rdns: 'io.leapwallet.CompassWallet',
    icon: <CompassWallet />,
  },
  'io.magiceden.wallet': {
    name: 'Magic Eden',
    rdns: 'io.magiceden.wallet',
    icon: <MagicEdenWallet />,
  },
  'io.rabby': {
    name: 'Rabby',
    rdns: 'io.rabby',
    icon: <RabbyWallet />,
  },
  'io.xdefi': {
    name: 'XDEFI',
    rdns: 'io.xdefi',
    icon: <XDeFiWallet />,
  },
  'io.zerion.wallet': {
    name: 'Zerion',
    rdns: 'io.zerion.wallet',
    icon: <ZerionWallet />,
  },
  'pro.tokenpocket': {
    name: 'TokenPocket',
    rdns: 'pro.tokenpocket',
    icon: <TokenPocketWallet />,
  },
  'sh.frame': {
    name: 'Frame',
    rdns: 'sh.frame',
    icon: <FrameWallet />,
  },
  'so.onekey.app.wallet': {
    name: 'OneKey',
    rdns: 'so.onekey.app.wallet',
    icon: <OneKeyWallet />,
  },
  'xyz.frontier.wallet': {
    name: 'Frontier',
    rdns: 'xyz.frontier.wallet',
    icon: <FrontierWallet />,
  },
  'xyz.nestwallet': {
    name: 'Nest',
    rdns: 'xyz.nestwallet',
    icon: <NestWallet />,
  },
  'xyz.talisman': {
    name: 'Talisman',
    rdns: 'xyz.talisman',
    icon: <TalismanWallet />,
  },
  'haha.me': {
    name: 'HaHa',
    rdns: 'haha.me',
    icon: <HahaWallet />,
  },
  'plugwallet.ooo': {
    name: 'Plug',
    rdns: 'plugwallet.ooo',
    icon: <PlugWallet />,
  },
  'org.uniswap.app': {
    name: 'Uniswap',
    rdns: 'org.uniswap.app',
    icon: <UniWallet />,
  },
  'app.keplr': {
    name: 'Keplr',
    rdns: 'app.keplr',
    icon: <KeplrWallet />,
  },
  'com.exodus.web3-wallet': {
    name: 'Exodus',
    rdns: 'com.exodus.web3-wallet',
    icon: <ExodusWallet />,
  },
  'xyz.taho.wallet': {
    name: 'Taho',
    rdns: 'xyz.taho.wallet',
    icon: <TahoWallet />,
  },
  'com.fluentwallet': {
    name: 'Fluent Wallet',
    rdns: 'com.fluentwallet',
    icon: <FluentWallet />,
  },
  'org.hot-labs': {
    name: 'Hot Wallet',
    rdns: 'org.hot-labs',
    icon: <HotWallet />,
  },
  'https://www.safepal.com/download': {
    name: 'SafePal',
    rdns: 'https://www.safepal.com/download',
    icon: <SafePalWallet />,
  },
  'https://www.tokenpocket.pro/en/download/app': {
    name: 'TokenPocket',
    rdns: 'https://www.tokenpocket.pro/en/download/app',
    icon: <TokenPocketWallet />,
  },
};
