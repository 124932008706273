import { FC, HTMLAttributes } from 'react';

type BestWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BestWallet: FC<BestWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 148 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="148" height="148" rx="34.6875" fill="#5860FF" />
      <path
        d="M31.0781 92.3217C31.0781 80.4737 31.0781 74.5497 33.5089 69.6757C34.6015 67.4849 36.035 65.4816 37.7559 63.7404C41.5847 59.8666 47.1917 57.9547 58.4057 54.1309L80.4805 46.6038C96.5481 41.125 104.582 38.3856 110.575 41.4463C111.983 42.1654 113.276 43.0906 114.411 44.1913C119.242 48.8758 119.242 57.3638 119.242 74.3399C119.242 86.0943 119.242 91.9715 116.53 96.6676C115.873 97.8061 115.106 98.8778 114.241 99.8674C110.671 103.95 105.108 105.847 93.9828 109.64L67.7716 118.578C53.6249 123.402 46.5515 125.814 41.0975 123.643C34.1089 120.861 31.0781 114.396 31.0781 108.832C31.0781 105.074 31.0781 98.151 31.0781 92.3217Z"
        fill="url(#paint0_linear_9893_192587)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0781 64.3773C31.0781 54.8879 31.0781 47.108 32.6557 43.0588C34.049 39.4827 36.3104 36.3095 39.2361 33.8255C42.5488 31.0129 47.0337 29.4645 56.0037 26.3677L59.0956 25.3003C69.5119 21.7042 74.72 19.9061 78.9012 20.7147C83.4262 21.5897 87.3487 24.3852 89.6525 28.3769C91.7812 32.0653 91.7812 40.4657 91.7812 51.4853C91.7812 60.18 91.7812 64.5273 90.4529 68.2755C89.0088 72.3502 86.4514 75.9386 83.071 78.6333C79.9615 81.1121 75.8521 82.5307 67.6334 85.3681C67.6334 85.3681 53.4805 90.2034 44.6641 93.2842C35.8477 96.365 31.0781 102.545 31.0781 108.973V64.3773Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9893_192587"
          x1="116.135"
          y1="95.6074"
          x2="34.6791"
          y2="48.7999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.12" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BestWallet;
