import { FC, HTMLAttributes } from 'react';

type SamsungInternetProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const SamsungInternet: FC<SamsungInternetProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      version="1.1"
      viewBox="0 0 493 493"
      width={size}
      height={size}
      {...props}
    >
      <path
        fill="#7882FF"
        d="M488.5 173c-6.1-45.8-22.3-87.3-50.8-117.7C407.3 26.8 365.8 10.6 320 4.5 276.3-1.3 246.5.1 246.5.1S216.7-1.3 173 4.5c-45.8 6.1-87.3 22.3-117.6 50.8C26.8 85.7 10.6 127.2 4.5 173-1.3 216.7.1 246.5.1 246.5s-1.4 29.8 4.4 73.5c6.1 45.8 22.3 87.3 50.8 117.7 30.4 28.6 71.9 44.8 117.6 50.9 43.7 5.8 73.5 4.4 73.5 4.4s29.8 1.4 73.5-4.4c45.8-6.1 87.3-22.3 117.6-50.9 28.6-30.4 44.8-71.9 50.8-117.7 5.8-43.7 4.4-73.5 4.4-73.5s1.6-29.8-4.2-73.5"
      ></path>
      <path
        fill="#FFF"
        d="M372.7 210c19.5 70-21.5 142.6-91.5 162.1s-142.6-21.5-162.1-91.5 21.5-142.6 91.5-162.1S353.2 140 372.7 210"
      ></path>
      <path
        fill="#FFF"
        d="M372.7 210c7.3 26.3 6.1 52.9-1.9 76.9-.6 1.9-1.3 3.9-2.1 5.8"
      ></path>
      <path fill="#FFF" d="M370.6 210c5.1 18.4 6.1 37 3.4 54.7"></path>
      <path
        fill="#4D5CC1"
        d="M333.9 337.7c-33.3 0-73.8-8.5-114.2-23.8s-76.3-35.9-101.2-58c-40.4-35.9-37.9-63.7-32.8-77.1s21.7-35.9 75.7-35.9c33.3 0 73.8 8.5 114.2 23.8s76.3 35.9 101.2 58c40.4 35.9 37.9 63.7 32.8 77.1-5 13.4-21.6 35.9-75.7 35.9M161.5 184.6c-25 0-35.7 6.2-36.8 9s2.9 14.6 21.6 31.2c20.9 18.5 53.1 36.9 88.3 50.2 35.2 13.4 71.5 21.1 99.4 21.1 25 0 35.7-6.2 36.8-9s-2.9-14.6-21.5-31.2c-20.9-18.5-53.1-36.9-88.3-50.2-35.4-13.4-71.6-21.1-99.5-21.1"
      ></path>
      <path
        fill="#FFF"
        d="m124.7 194 245.9 93.4c8.2-24.2 9.5-51 2.1-77.4-19.5-70-92-111-162.1-91.5-40.2 11.2-70.8 39.9-85.9 75.5"
      ></path>
    </svg>
  );
};

export default SamsungInternet;
