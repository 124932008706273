import { FC, HTMLAttributes } from 'react';

type LinkedInProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const LinkedIn: FC<LinkedInProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 176 176"
      {...props}
    >
      <g id="linkedin">
        <rect
          id="background"
          width="176"
          height="176"
          fill="#0077b5"
          rx="24"
        ></rect>
        <g id="icon" fill="#fff">
          <path d="M63.4 48a15 15 0 1 1-15-15 15 15 0 0 1 15 15M60 73v66.27a3.71 3.71 0 0 1-3.71 3.73H40.48a3.71 3.71 0 0 1-3.72-3.72V73a3.72 3.72 0 0 1 3.72-3.72h15.81A3.72 3.72 0 0 1 60 73M142.64 107.5v32.08a3.41 3.41 0 0 1-3.42 3.42h-17a3.41 3.41 0 0 1-3.42-3.42v-31.09c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86A3.42 3.42 0 0 1 90.3 143H73.88a3.41 3.41 0 0 1-3.41-3.42V72.71a3.41 3.41 0 0 1 3.41-3.42H90.3a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.82 9.63-10.31 21.9-10.31 27.18 0 27.02 25.38 27.02 39.32"></path>
        </g>
      </g>
    </svg>
  );
};

export default LinkedIn;
