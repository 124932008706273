export { default as BraveBrowser } from './Brave';
export { default as Chrome } from './Chrome';
export { default as DuckDuckGo } from './DuckDuckGo';
export { default as Edge } from './Edge';
export { default as Firefox } from './Firefox';
export { default as IE } from './IE';
export { default as Opera } from './Opera';
export { default as Safari } from './Safari';
export { default as Avast } from './Avast';
export { default as Baidu } from './Baidu';
export { default as WeChat } from './WeChat';
export { default as Weibo } from './Weibo';
export { default as Yandex } from './Yandex';
export { default as Chromium } from './Chromium';
export { default as SamsungInternet } from './SamsungInternet';
export { default as Snapchat } from './Snapchat';
export { default as LinkedIn } from './LinkedIn';
export { default as Facebook } from './Facebook';
export { default as Twitter } from './Twitter';
export { default as Instagram } from './Instagram';
export { default as Zalo } from './Zalo';
