import { FC, HTMLAttributes } from 'react';

type OperaProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Opera: FC<OperaProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="fi_6124994"
      width={size}
      height={size}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 512.001 512.001"
      {...props}
    >
      <linearGradient
        id="SVGID_1_"
        x1="213.312"
        x2="213.312"
        y1="8.348"
        y2="504.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stopColor="#FF1B2D"></stop>
        <stop offset="0.438" stopColor="#FA1A2C"></stop>
        <stop offset="0.594" stopColor="#ED1528"></stop>
        <stop offset="0.758" stopColor="#D60E21"></stop>
        <stop offset="0.927" stopColor="#B70519"></stop>
        <stop offset="1" stopColor="#A70014"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M171.737 400.271c-28.302-33.411-46.637-82.808-47.882-138.238-.004-.143-.004-11.918 0-12.061 1.245-55.429 19.579-104.825 47.88-138.235 36.728-47.692 91.843-66.439 152.807-66.439 37.504 0 102.081 19.869 102.081 19.869C381.558 24.848 322.138.253 256.968.012c-.323-.001-.644-.012-.967-.012-141.385 0-256 114.615-256 256.001 0 137.295 108.085 249.338 243.808 255.704 4.042.19 8.104.297 12.192.297 65.542 0 125.317-24.642 170.604-65.151 0 0-62.116 14.14-99.614 14.14-60.965-.001-118.528-13.03-155.254-60.72"
      ></path>
      <linearGradient
        id="SVGID_00000067216744952683909510000000913572146786459031_"
        x1="341.868"
        x2="341.868"
        y1="37.594"
        y2="476.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9C0000"></stop>
        <stop offset="0.7" stopColor="#FF4B4B"></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_00000067216744952683909510000000913572146786459031_)"
        d="M171.735 111.737c23.489-27.729 53.836-44.453 86.982-44.453 74.523 0 134.936 84.493 134.936 188.719S333.24 444.721 258.717 444.721c-33.145 0-63.492-16.724-86.98-44.451 36.726 47.69 91.319 77.93 152.283 77.93 37.498 0 72.581-11.448 102.585-31.351 52.404-46.876 85.396-115.005 85.396-190.849 0-75.836-32.984-143.958-85.378-190.834-30.008-19.91-65.099-31.362-102.603-31.362-60.964.001-115.558 30.242-152.285 77.933"
      ></path>
    </svg>
  );
};

export default Opera;
