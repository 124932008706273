import { FC, HTMLAttributes } from 'react';

type RoninWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const RoninWallet: FC<RoninWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 100 100"
      {...props}
    >
      <path fill="url(#roninwallet)" d="M0 0h100v100H0z" />
      <path
        fill="#fff"
        d="M75.984 37.962V21.32A8.32 8.32 0 0 0 67.667 13h-35.35A8.316 8.316 0 0 0 24 21.32v48.286a8.32 8.32 0 0 0 3.12 6.49l12.87 10.338a1.04 1.04 0 0 0 1.695-.811v-34.14a1.04 1.04 0 0 1 1.04-1.04h9.357a6.237 6.237 0 0 1 6.238 6.24v28.94a1.04 1.04 0 0 0 1.69.811l12.87-10.338a8.32 8.32 0 0 0 3.12-6.49V54.603a8.32 8.32 0 0 0-8.317-8.32 8.316 8.316 0 0 0 8.301-8.321m-23.912 4.16h-9.358a1.04 1.04 0 0 1-1.04-1.04V22.36a1.04 1.04 0 0 1 1.04-1.04H57.27a1.04 1.04 0 0 1 1.04 1.04v13.52a6.24 6.24 0 0 1-6.238 6.241"
      />
      <defs>
        <linearGradient
          id="roninwallet"
          x1="-3"
          x2="104"
          y1="103"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0606B7" />
          <stop offset=".625" stopColor="#137CCA" />
          <stop offset="1" stopColor="#18A5D1" />
          <stop offset="1" stopColor="#20A8D0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RoninWallet;
