export class LocalStorage {
  private readonly json_prefix = '__json=';

  public setItem(key: string, value: any): void {
    try {
      let storeValue = value;
      if (typeof value === 'object' && value !== null) {
        storeValue = this.json_prefix + JSON.stringify(value);
      }
      localStorage.setItem(key, String(storeValue));
    } catch (error) {
      console.error('[ERROR] Failed to set item in localStorage', error);
    }
  }

  public getItem(key: string): string | Record<string, any> | null {
    try {
      const value = localStorage.getItem(key);
      if (!value || typeof value !== 'string') return null;
      if (['null', 'undefined'].includes(value)) return null;

      if (value.startsWith(this.json_prefix)) {
        try {
          return JSON.parse(value.slice(this.json_prefix.length));
        } catch (error) {
          console.error('[ERROR] LocalStorage failed to parse JSON', error);
          return null;
        }
      }
      return value;
    } catch (error) {
      console.error('[ERROR] Failed to get item from localStorage', error);
      return null;
    }
  }

  public removeMatch(pattern: RegExp): void {
    try {
      for (const key in localStorage) {
        if (pattern.test(key)) {
          this.removeItem(key);
        }
      }
    } catch (error) {
      console.error(
        '[ERROR] Failed to remove matching items from localStorage',
        error,
      );
    }
  }

  public removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('[ERROR] Failed to remove item from localStorage', error);
    }
  }

  public clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('[ERROR] Failed to clear localStorage', error);
    }
  }
}

export default new LocalStorage();
