import { FC, HTMLAttributes } from 'react';

type ZaloProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Zalo: FC<ZaloProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="#0068FF"
        fillRule="evenodd"
        d="M22.782.166h4.417c6.066 0 9.611.891 12.758 2.578s5.63 4.152 7.299 7.299c1.687 3.147 2.578 6.692 2.578 12.758v4.398c0 6.066-.891 9.611-2.578 12.758s-4.152 5.63-7.299 7.299c-3.147 1.687-6.692 2.578-12.758 2.578h-4.398c-6.066 0-9.611-.89-12.758-2.578s-5.63-4.152-7.299-7.299C1.057 36.81.166 33.265.166 27.2v-4.398c0-6.066.891-9.611 2.578-12.758s4.152-5.63 7.299-7.299C13.17 1.057 16.735.166 22.782.166"
        clipRule="evenodd"
      ></path>
      <path
        fill="#001A33"
        fillRule="evenodd"
        d="M49.834 26.474v.725c0 6.067-.891 9.612-2.579 12.759-1.687 3.147-4.151 5.63-7.298 7.298-3.147 1.687-6.692 2.578-12.758 2.578H22.8c-4.964 0-8.24-.596-10.99-1.737l-4.536-4.67z"
        clipRule="evenodd"
        opacity="0.12"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.779 43.59c2.323.256 5.227-.406 7.29-1.408 8.954 4.95 22.95 4.713 31.423-.709a19 19 0 0 0 .92-1.537C49.107 36.778 50 33.22 50 27.132v-4.414c0-6.089-.894-9.647-2.587-12.805-1.674-3.159-4.167-5.632-7.325-7.325S33.37 0 27.283 0H22.85c-5.186 0-8.552.653-11.38 1.899q-.232.208-.455.422c-8.298 8-8.928 25.338-1.892 34.757l.026.042c1.084 1.599.038 4.395-1.598 6.032-.267.247-.172.4.228.437"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0068FF"
        d="M20.563 17h-9.725v2.085h6.749l-6.654 8.247c-.209.303-.36.587-.36 1.232v.53h9.175c.455 0 .834-.378.834-.833v-1.119h-7.09l6.256-7.848c.095-.114.265-.322.341-.417l.038-.057c.36-.53.436-.986.436-1.536zM32.942 29.095h1.383V17H32.24v11.393a.7.7 0 0 0 .702.702M25.814 19.692a4.74 4.74 0 0 0-4.74 4.74 4.74 4.74 0 0 0 4.74 4.739 4.74 4.74 0 0 0 4.74-4.74c.018-2.615-2.105-4.739-4.74-4.739m0 7.526a2.79 2.79 0 0 1-2.787-2.786 2.79 2.79 0 0 1 2.787-2.787 2.79 2.79 0 0 1 2.787 2.787 2.78 2.78 0 0 1-2.787 2.786M40.487 19.616a4.78 4.78 0 0 0-4.778 4.777 4.78 4.78 0 0 0 4.778 4.778 4.78 4.78 0 0 0 4.777-4.778 4.78 4.78 0 0 0-4.777-4.777m0 7.602a2.8 2.8 0 0 1-2.806-2.806 2.8 2.8 0 0 1 2.806-2.805 2.8 2.8 0 0 1 2.805 2.805 2.8 2.8 0 0 1-2.805 2.806"
      ></path>
      <path
        fill="#0068FF"
        d="M29.456 29.094h1.119v-9.137h-1.953v8.322c0 .436.38.815.834.815"
      ></path>
    </svg>
  );
};

export default Zalo;
