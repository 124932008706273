import { FC, HTMLAttributes } from 'react';

type PlugWalletWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const PlugWallet: FC<PlugWalletWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1401.54 -2138.7 1940.47 2813.5931"
      width={size}
      height={size}
      {...props}
    >
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1294.82"
          y1="1305.45"
          x2="1992.91"
          y2="606.659"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#46FF47" />
          <stop offset="1" stopColor="#9CFF9D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="1294.82"
          y1="1305.45"
          x2="1992.91"
          y2="606.659"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#46FF47" />
          <stop offset="1" stopColor="#9CFF9D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="1294.82"
          y1="1305.45"
          x2="1992.91"
          y2="606.659"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#46FF47" />
          <stop offset="1" stopColor="#9CFF9D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="1126.15"
          y1="1439.4"
          x2="1469.88"
          y2="982.985"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#10D9ED" />
          <stop offset="1" stopColor="#10D9ED" stopOpacity="0.3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="1049.61"
          y1="1601.69"
          x2="1282.77"
          y2="1152.37"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#FA51D3" />
          <stop offset="0.9588" stopColor="#FA51D3" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="727.851"
          y1="2262.22"
          x2="1076.54"
          y2="1370.65"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop stopColor="#FFE700" />
          <stop offset="1" stopColor="#FFE700" stopOpacity="0" />
        </linearGradient>
        <filter
          id="filter0_i"
          x="475.518"
          y="1681.9"
          width="992.213"
          height="418.727"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.64 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint6_linear"
          x1="971.649"
          y1="1679.65"
          x2="971.649"
          y2="2100.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.65" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="1107.72"
          y1="1740.6"
          x2="1107.72"
          y2="1854"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop offset="0.75" stopColor="white" />
          <stop offset="1" stopColor="#DEDEDF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="734.034"
          y1="1056.29"
          x2="734.033"
          y2="1432.8"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop offset="0.75" stopColor="white" />
          <stop offset="1" stopColor="#DEDEDF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="1206.38"
          y1="1056.29"
          x2="1206.38"
          y2="1432.8"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1, 0, 0, 1, 1401.549316, -2138.706894)"
        >
          <stop offset="0.75" stopColor="white" />
          <stop offset="1" stopColor="#DEDEDF" />
        </linearGradient>
        <filter
          id="filter1_i"
          x="306.154"
          y="914.149"
          width="1329.54"
          height="664.771"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.64 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint10_linear"
          x1="970.925"
          y1="914.149"
          x2="970.925"
          y2="1578.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0.65" />
        </linearGradient>
      </defs>
      <path
        d="M 1764.44 -2078.7 C 1764.44 -2111.9 1791.34 -2138.7 1824.48 -2138.7 L 1987.64 -2138.7 C 2020.77 -2138.7 2047.64 -2111.9 2047.64 -2078.7 L 2047.64 -1444.5 L 1764.44 -1444.5 L 1764.44 -2078.7 Z"
        fill="#031514"
      />
      <path
        d="M 2690.13 -2078.7 C 2690.13 -2111.9 2716.99 -2138.7 2750.13 -2138.7 L 2913.28 -2138.7 C 2946.42 -2138.7 2973.28 -2111.9 2973.28 -2078.7 L 2973.28 -1444.5 L 2690.13 -1444.5 L 2690.13 -2078.7 Z"
        fill="#031514"
      />
      <path
        d="M 1401.54 -1330.8 C 1401.54 -1393.4 1452.34 -1444.2 1514.94 -1444.2 L 3228.62 -1444.2 C 3291.24 -1444.2 3342.01 -1393.4 3342.01 -1330.8 L 3342.01 -765.93 C 3342.01 -230.09 2907.62 204.303 2371.78 204.303 C 1835.94 204.303 1401.54 -230.09 1401.54 -765.93 L 1401.54 -1330.8 Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M 2047.9 122.093 L 2695.66 122.093 L 2695.66 202.883 C 2695.66 320.313 2600.47 415.503 2483.05 415.503 L 2260.51 415.503 C 2143.09 415.503 2047.9 320.313 2047.9 202.883 L 2047.9 122.093 Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M 2152.79 381.483 L 2590.77 381.483 L 2590.77 533.143 C 2590.77 611.433 2527.31 674.893 2449.03 674.893 L 2294.53 674.893 C 2216.25 674.893 2152.79 611.433 2152.79 533.143 L 2152.79 381.483 Z"
        fill="url(#paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 3265.19 -386.91 C 3115.7 -932.6 2616.23 -1333.6 2023.09 -1333.6 C 1797.7 -1333.6 1585.84 -1275.7 1401.54 -1174 L 1401.54 -765.93 C 1401.54 -343.66 1671.34 15.603 2047.9 148.923 L 2047.9 202.883 C 2047.9 280.993 2090.02 349.273 2152.79 386.233 L 2152.79 533.143 C 2152.79 611.433 2216.25 674.893 2294.53 674.893 L 2449.03 674.893 C 2527.31 674.893 2590.77 611.433 2590.77 533.143 L 2590.77 386.233 C 2653.54 349.273 2695.66 280.993 2695.66 202.883 L 2695.66 148.923 C 2952.31 58.063 3159.35 -137.74 3265.19 -386.91 Z"
        fill="url(#paint3_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 3033.2 -56.087 C 2985.68 -602.78 2526.83 -1031.7 1967.81 -1031.7 C 1759.84 -1031.7 1565.74 -972.34 1401.54 -869.65 L 1401.54 -765.93 C 1401.54 -343.66 1671.34 15.603 2047.9 148.933 L 2047.9 202.883 C 2047.9 280.993 2090.02 349.273 2152.79 386.233 L 2152.79 533.143 C 2152.79 611.433 2216.25 674.893 2294.53 674.893 L 2449.03 674.893 C 2527.31 674.893 2590.77 611.433 2590.77 533.143 L 2590.77 386.233 C 2653.54 349.273 2695.66 280.993 2695.66 202.883 L 2695.66 148.933 C 2822.33 104.083 2936.91 33.683 3033.2 -56.087 Z"
        fill="url(#paint4_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 2696.73 148.733 C 2676.58 -296.8 2308.99 -651.83 1858.48 -651.83 C 1701.94 -651.83 1555.34 -608.94 1429.94 -534.27 C 1507.94 -215.89 1743.04 40.813 2048.42 148.933 L 2048.42 202.883 C 2048.42 280.993 2090.54 349.273 2153.31 386.233 L 2153.31 533.143 C 2153.31 611.433 2216.77 674.893 2295.05 674.893 L 2449.55 674.893 C 2527.83 674.893 2591.29 611.433 2591.29 533.143 L 2591.29 386.233 C 2654.06 349.273 2696.18 280.993 2696.18 202.883 L 2696.18 148.933 C 2696.37 148.863 2696.55 148.803 2696.73 148.733 Z"
        fill="url(#paint5_linear)"
      />
      <g
        filter="url(#filter0_i)"
        transform="matrix(1, 0, 0, 1, 1401.54931640625, -2138.706787109375)"
      >
        <path
          d="M487.257 1682.09C480.919 1680.85 475.079 1685.87 475.543 1692.32C491.962 1920.23 707.814 2100.63 971.682 2100.63C1234.94 2100.63 1450.4 1921.07 1467.7 1693.9C1468.19 1687.46 1462.37 1682.41 1456.03 1683.63C1302.66 1713.12 1137.83 1741.65 971.682 1741.65C802.603 1741.65 642.641 1712.56 487.257 1682.09Z"
          fill="url(#paint6_linear)"
        />
      </g>
      <path
        d="M 1907.57 -420.79 C 1916.11 -348.65 2126.28 -260.62 2376.42 -260.62 C 2626 -260.62 2832.27 -348.88 2841.66 -420.79 C 2695.64 -411.44 2683.62 -364.93 2376.42 -364.93 C 2053.57 -364.93 2055.83 -411.12 1907.57 -420.79 Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M 2335.82 -891.85 C 2262.82 -757.85 2148.72 -622.43 2016.9 -625.97 C 1882.26 -625.97 1773.14 -745.01 1773.14 -891.85 C 1773.14 -1038.7 1882.26 -1157.7 2016.9 -1157.7 C 2151.55 -1157.7 2250.78 -1040 2335.82 -891.85 Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M 2407.69 -891.46 C 2480.69 -760.12 2594.79 -622.5 2726.61 -625.97 C 2861.26 -625.97 2970.41 -747.54 2970.41 -891.46 C 2970.41 -1035.4 2861.97 -1157.7 2727.32 -1157.7 C 2592.67 -1157.7 2492.74 -1036.7 2407.69 -891.46 Z"
        fill="url(#paint9_linear)"
      />
      <circle cx="2111.99" cy="-847.13" r="64.087" fill="#031514" />
      <circle cx="2134.38" cy="-818.56" r="21.62" fill="white" />
      <circle cx="2632.18" cy="-847.13" r="64.087" fill="#031514" />
      <circle cx="2654.57" cy="-818.56" r="21.62" fill="white" />
      <path
        d="M 2337.17 -889.24 L 1774.54 -860.19 C 1773.34 -870.82 1772.44 -875.78 1772.44 -883.57 C 1770.94 -1040.1 1880.82 -1159.2 2019 -1159.2 C 2157.18 -1159.2 2264.89 -1022.4 2335.75 -892.78 C 2335.16 -891.69 2337.77 -890.33 2337.17 -889.24 Z"
        fill="#031514"
      />
      <path
        d="M 2406.34 -889.24 L 2969.57 -860.19 C 2970.78 -870.82 2970.97 -875.78 2970.97 -883.57 C 2975.95 -1037.3 2862.58 -1159.2 2724.43 -1159.2 C 2586.29 -1159.2 2478.61 -1022.4 2407.76 -892.78 C 2408.35 -891.69 2405.74 -890.33 2406.34 -889.24 Z"
        fill="#031514"
      />
      <g
        filter="url(#filter1_i)"
        transform="matrix(1, 0, 0, 1, 1401.54931640625, -2138.706787109375)"
      >
        <path
          d="M1321.76 914.149C1150.23 914.149 1025.37 1085.43 970.925 1176.84C916.476 1085.43 791.624 914.149 620.092 914.149C446.961 914.149 306.154 1063.27 306.154 1246.53C306.154 1429.8 446.961 1578.92 620.092 1578.92C791.603 1578.92 916.476 1407.64 970.925 1316.21C1025.37 1407.64 1150.23 1578.92 1321.76 1578.92C1494.89 1578.92 1635.7 1429.8 1635.7 1246.53C1635.7 1063.27 1494.89 914.149 1321.76 914.149ZM620.092 1512.44C483.648 1512.44 372.631 1393.16 372.631 1246.53C372.631 1099.91 483.648 980.627 620.092 980.627C782.192 980.627 903.596 1189.63 933.656 1246.53C903.596 1303.44 782.213 1512.44 620.092 1512.44ZM1321.76 1512.44C1159.66 1512.44 1038.25 1303.44 1008.19 1246.53C1038.25 1189.63 1159.66 980.627 1321.76 980.627C1458.2 980.627 1569.22 1099.91 1569.22 1246.53C1569.22 1393.16 1458.2 1512.44 1321.76 1512.44Z"
          fill="url(#paint10_linear)"
        />
      </g>
    </svg>
  );
};

export default PlugWallet;
