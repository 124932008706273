import { ObjectSnakeCaseToCamelCase, Team, User, UserTeam } from '@formo/types';
import { z } from 'zod';

import { TEAM_ROLES } from '../constants';
import { filterRequest } from './common';

export const getTeamsOfUserRequest = z.object({
  params: filterRequest,
});
export type GetTeamsOfUserRequest = z.infer<typeof getTeamsOfUserRequest>;
export type GetTeamsOfUserResponse = {
  teamId: string;
  role: string;
  team: {
    id: string;
    teamName: string;
    customerId: string | null;
    subscriptionId: string | null;
    _count: {
      Form: number;
    };
  };
}[];

export const createTeamRequest = z.object({
  body: z.object({
    name: z.string().min(1, 'Team name cannot be empty'),
  }),
});
export type CreateTeamRequest = z.infer<typeof createTeamRequest>;
export type CreateTeamResponse = ObjectSnakeCaseToCamelCase<Team>;

// Teams Management
export const updateTeamNameRequest = z.object({
  body: z.object({
    name: z.string().min(1, 'Team name cannot be empty'),
  }),
  params: z.object({
    teamId: z.string().uuid(),
  }),
});
export type UpdateTeamNameRequest = z.infer<typeof updateTeamNameRequest>;
export type UpdateTeamNameResponse = ObjectSnakeCaseToCamelCase<Team>;

export const updateTeamTierRequest = z.object({
  body: z.object({
    customerId: z.string().optional(),
    subscriptionId: z.string().optional(),
  }),
  params: z.object({
    teamId: z.string().uuid(),
  }),
});
export type UpdateTeamTierRequest = z.infer<typeof updateTeamTierRequest>;
export type UpdateTeamTierResponse = ObjectSnakeCaseToCamelCase<Team> | string;

export const deleteTeamRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
});

export type DeleteTeamRequest = z.infer<typeof deleteTeamRequest>;
export type DeleteTeamResponse = null;

export const transferOwnershipRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
  body: z.object({
    userId: z.string().uuid(),
  }),
});
export type TransferOwnershipRequest = z.infer<typeof transferOwnershipRequest>;
export type TransferOwnershipResponse = ObjectSnakeCaseToCamelCase<UserTeam>;

// Members Management
export const getTeamMembersRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    ...filterRequest.shape,
  }),
});
export type GetTeamMembersRequest = z.infer<typeof getTeamMembersRequest>;
export type GetTeamMembersResponse = ObjectSnakeCaseToCamelCase<
  UserTeam & {
    user: User;
  }
>[];

export const addTeamMemberRequest = z.object({
  body: z.object({
    userId: z.string().uuid(),
    role: z.enum([TEAM_ROLES.ADMIN, TEAM_ROLES.MEMBER, TEAM_ROLES.EDITOR]),
  }),
  params: z.object({
    teamId: z.string().uuid(),
  }),
});
export type AddTeamMemberRequest = z.infer<typeof addTeamMemberRequest>;
export type AddTeamMemberResponse = ObjectSnakeCaseToCamelCase<UserTeam>;

export const removeTeamMemberRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
    userId: z.string().uuid(),
  }),
});
export type RemoveTeamMemberRequest = z.infer<typeof removeTeamMemberRequest>;
export type RemoveTeamMemberResponse = null;

export const updateMemberRoleRequest = z.object({
  body: z.object({
    role: z.enum([TEAM_ROLES.ADMIN, TEAM_ROLES.MEMBER, TEAM_ROLES.EDITOR]),
  }),
  params: z.object({
    teamId: z.string().uuid(),
    userId: z.string().uuid(),
  }),
});
export type UpdateMemberRoleRequest = z.infer<typeof updateMemberRoleRequest>;
export type UpdateMemberRoleResponse = ObjectSnakeCaseToCamelCase<UserTeam>;

export const leaveTeamRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
});
export type LeaveTeamRequest = z.infer<typeof leaveTeamRequest>;
export type LeaveTeamResponse = null;

// Permissions Management
export const getTeamPermissionsRequest = z.object({
  params: z.object({
    teamId: z.string().uuid(),
  }),
});
export type GetTeamPermissionsRequest = z.infer<
  typeof getTeamPermissionsRequest
>;
export type GetTeamPermissionsResponse = {
  role: TEAM_ROLES;
};
