import { FC, HTMLAttributes } from 'react';

type SnapchatProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Snapchat: FC<SnapchatProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      {...props}
    >
      <linearGradient
        id="snapchat-gradient"
        x1="133.543"
        x2="417.439"
        y1="349.788"
        y2="65.892"
        gradientTransform="matrix(1 0 0 -1 0 514)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#1d1d1b"></stop>
        <stop offset="0.369" stopColor="#1d1d1b" stopOpacity="0.856"></stop>
        <stop offset="0.75" stopColor="#1d1d1b" stopOpacity="0.429"></stop>
        <stop offset="1" stopColor="#1d1d1b" stopOpacity="0"></stop>
      </linearGradient>
      <circle cx="256" cy="256" r="250" fill="#ffd633"></circle>
      <path
        fill="url(#snapchat-gradient)"
        d="M503.9 288.49 329.705 114.3c-17.247-17.158-41.258-27.2-65.714-28.909-42.365-2.957-83.288 18.761-100.772 58.3-9.1 20.436-5.58 57.821-4.112 81.453-6.461 2.809-15.567-.194-21.931-3.2-13.127-6.214-34 9.459-20.185 23.464l36.321 36.234a124 124 0 0 1-17.412 26.889c-35.34 41.259-67.356 24.407-58.936 44.169l150.688 151.7A253 253 0 0 0 256 506c127.06 0 231.973-94.79 247.9-217.51"
        opacity="0.49"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#000"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="14"
        d="M263.991 85.386c33.655 2.349 66.467 20.487 81.97 51.224 13.761 27.284 9.146 59.3 7.391 88.628 9.105 4.165 18.994-4.746 27.316-4.746 18.5 0 31.917 21.211.685 33.608-6.559 2.518-21.343 5.521-24.672 13.366-4.6 10.75 25.26 64.794 72.646 72.639a8.373 8.373 0 0 1 7.049 8.814c-.881 15.4-32.994 21.113-48.365 23.535-5.385 10.653 1.077 26.731-22.029 22.179-32.015-6.3-42.589 1.646-57.667 12.3-69.9 49.685-101.43-8.523-129.235-13.172-20.658-3.487-33.777 2.131-40.729 2.131-12.826 0-10.084-16.368-13.9-23.438-13.8-2.131-42.1-7.167-47.485-19.758-8.42-19.758 23.6-2.906 58.939-44.165a125.3 125.3 0 0 0 18.309-28.765c8.516-17.53-6.856-19.366-23.014-25.766-37.4-14.818-9.986-39.612 5.972-32.058 6.364 3 15.469 6 21.931 3.2-1.468-23.631-4.993-61.017 4.112-81.452 17.485-39.542 58.411-61.261 100.776-58.304z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Snapchat;
