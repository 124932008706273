import { FC, HTMLAttributes } from 'react';

type IHahaWalletWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const HahaWallet: FC<IHahaWalletWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 97 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="96" height="96" rx="14.4" fill="url(#HahaWallet)" />
      <path
        d="M67.1992 20.7988V75.1988"
        stroke="white"
        strokeWidth="16"
        strokeLinejoin="round"
      />
      <path
        d="M28.8008 20.7988V75.1988"
        stroke="white"
        strokeWidth="16"
        strokeLinejoin="round"
      />
      <path
        d="M75.1988 32.5879L20.7988 47.1643V63.7287L75.1988 49.1523V32.5879Z"
        fill="white"
      />
      <path
        d="M96.0012 43.5908V60.1552L75.2012 65.7286V49.1642L96.0012 43.5908Z"
        fill="white"
      />
      <path
        d="M0 52.7266V36.1622L20.8 30.5889V47.1533L0 52.7266Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="HahaWallet"
          x1="0"
          y1="96"
          x2="96"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11A9FF" />
          <stop offset="1" stopColor="#FC65FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HahaWallet;
