import { FC, HTMLAttributes } from 'react';

type AndroidProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Android: FC<AndroidProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 512.12 512.12"
      {...props}
    >
      <g fill="#4CAF50">
        <path d="M74.727 170.787c-17.673 0-32 14.327-32 32V352.12c0 17.673 14.327 32 32 32s32-14.327 32-32V202.787c0-17.673-14.327-32-32-32M437.393 170.787c-17.673 0-32 14.327-32 32V352.12c0 17.673 14.327 32 32 32s32-14.327 32-32V202.787c0-17.673-14.326-32-32-32M373.393 170.787H138.727c-5.891 0-10.667 4.776-10.667 10.667V352.12c-.005 25.348 17.831 47.197 42.667 52.267v75.733c0 17.673 14.327 32 32 32s32-14.327 32-32v-74.667h42.667v74.667c0 17.673 14.327 32 32 32s32-14.327 32-32v-75.733c24.836-5.07 42.672-26.919 42.667-52.267V181.454c-.001-5.891-4.777-10.667-10.668-10.667M333.607 44.323l26.005-25.984c4.237-4.093 4.354-10.845.262-15.083s-10.845-4.354-15.083-.262q-.133.129-.262.262L314.236 33.55a145.99 145.99 0 0 0-116.331 0L167.612 3.235c-4.237-4.093-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l25.984 26.005a112.58 112.58 0 0 0-50.453 94.464c0 5.891 4.776 10.667 10.667 10.667h234.667c5.891 0 10.667-4.776 10.667-10.667a112.58 112.58 0 0 0-50.454-94.463"></path>
      </g>
      <g fill="#FAFAFA">
        <circle cx="202.727" cy="96.12" r="10.667"></circle>
        <circle cx="309.393" cy="96.12" r="10.667"></circle>
      </g>
    </svg>
  );
};

export default Android;
