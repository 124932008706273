import { FC, HTMLAttributes } from 'react';

type ChromiumProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Chromium: FC<ChromiumProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#82B1EA"
        d="M482.854 137.3 370.343 93.68l-94.745 43.701c57.071 9.36 100.62 58.9 100.62 118.62a120.22 120.22 0 0 1-30.339 79.667l-31.662 42.64-91 54.254 19.875 79.116q6.415.32 12.908.322c141.385 0 256-114.615 256-256 0-42.843-10.541-83.218-29.146-118.7"
      ></path>
      <path
        fill="#006BDA"
        d="M256 0C163.962 0 83.281 48.579 38.165 121.486L51.09 221.999l86.974 56.814c.463 7.488-2.277-22.275-2.283-22.813.001-66.395 53.824-120.218 120.219-120.219 6.233.05 19.107 1.307 25.257 2.321l201.597-.803C440.062 55.688 354.542 0 256 0"
      ></path>
      <path
        fill="#3699F8"
        d="M38.165 121.486C13.975 160.576 0 206.653 0 256c0 137.056 107.705 248.954 243.093 255.677l102.787-176.01a120.22 120.22 0 0 1-89.879 40.551c-57.562-.047-107.013-40.89-117.936-97.406z"
      ></path>
      <circle cx="256" cy="256" r="120.22" fill="#FFF"></circle>
      <circle cx="256" cy="256" r="99.2" fill="#0088F7"></circle>
    </svg>
  );
};

export default Chromium;
