import { FC, HTMLAttributes } from 'react';

type IOSProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const IOS: FC<IOSProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      {...props}
    >
      <path d="M15 3C8.385 3 3 8.385 3 15v20c0 6.615 5.385 12 12 12h20c6.615 0 12-5.385 12-12V15c0-6.615-5.385-12-12-12zm0 2h20c5.535 0 10 4.465 10 10v20c0 5.535-4.465 10-10 10H15C9.465 45 5 40.535 5 35V15C5 9.465 9.465 5 15 5m-3.385 13.066c-.703 0-1.22.502-1.22 1.16 0 .65.517 1.151 1.22 1.151s1.223-.5 1.223-1.15c0-.66-.52-1.16-1.223-1.16m10.422.57c-3.639 0-5.924 2.55-5.924 6.645 0 4.087 2.241 6.653 5.924 6.653 3.674 0 5.906-2.575 5.906-6.653 0-4.087-2.25-6.643-5.906-6.644m12.93 0c-2.768 0-4.615 1.503-4.615 3.762 0 1.863 1.045 2.972 3.34 3.508l1.634.397c1.68.395 2.418.974 2.418 1.941 0 1.125-1.16 1.942-2.742 1.942-1.695 0-2.873-.764-3.041-1.967h-1.953c.14 2.268 2.03 3.717 4.842 3.717 3.006 0 4.886-1.52 4.886-3.946 0-1.907-1.072-2.962-3.673-3.568l-1.397-.344c-1.652-.387-2.32-.905-2.32-1.81 0-1.143 1.037-1.89 2.593-1.89 1.477 0 2.495.728 2.68 1.897h1.916c-.114-2.136-1.993-3.638-4.568-3.638m-12.93 1.837c2.41 0 3.895 1.864 3.895 4.808 0 2.926-1.487 4.817-3.895 4.817-2.434 0-3.91-1.89-3.91-4.817 0-2.944 1.52-4.807 3.91-4.808m-11.361 1.584v9.57h1.88v-9.57z"></path>
    </svg>
  );
};

export default IOS;
