import { FC, HTMLAttributes } from 'react';

type TrustWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const TrustWallet: FC<TrustWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="#fff" d="M0 0h28v28H0z" />
      <path
        fill="#0500FF"
        d="M6 7.583 13.53 5v17.882C8.15 20.498 6 15.928 6 13.345V7.583Z"
      />
      <path
        fill="url(#trustwallet)"
        d="M22 7.583 13.53 5v17.882c6.05-2.384 8.47-6.954 8.47-9.537V7.583Z"
      />
      <defs>
        <linearGradient
          id="trustwallet"
          x1="19.768"
          x2="14.072"
          y1="3.753"
          y2="22.853"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#00F" />
          <stop offset=".08" stopColor="#0094FF" />
          <stop offset=".16" stopColor="#48FF91" />
          <stop offset=".42" stopColor="#0094FF" />
          <stop offset=".68" stopColor="#0038FF" />
          <stop offset=".9" stopColor="#0500FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TrustWallet;
