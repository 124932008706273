import { FC, HTMLAttributes } from 'react';

type Coin98WalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Coin98Wallet: FC<Coin98WalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <g clipPath="url(#coin98-a)">
        <path fill="url(#coin98-b)" d="M0 0h28v28H0z" />
        <path
          fill="#252525"
          d="M9.428 7a4.372 4.372 0 0 0-3.088 7.461A4.371 4.371 0 1 0 9.428 7Zm0 6.951a2.58 2.58 0 1 1 2.58-2.579 2.587 2.587 0 0 1-2.58 2.58Zm12.888-3.212a3.678 3.678 0 0 1-.46 1.789 5.022 5.022 0 0 0-1.57-.865 1.946 1.946 0 1 0-3.66-.924c-.001.323.08.641.235.924a5.026 5.026 0 0 0-1.57.865A3.735 3.735 0 0 1 18.575 7a3.745 3.745 0 0 1 3.74 3.74Zm-3.741 1.513a4.372 4.372 0 0 0-3.098 7.46 4.371 4.371 0 0 0 7.466-3.084 4.38 4.38 0 0 0-4.368-4.376Zm0 6.947a2.576 2.576 0 1 1 .006-5.152 2.576 2.576 0 0 1-.006 5.152Zm-5.406-1.945a3.741 3.741 0 0 1-7.484 0H7.48a1.945 1.945 0 0 0 3.893 0h1.796Z"
        />
      </g>
      <defs>
        <linearGradient
          id="coin98-b"
          x1="26.572"
          x2="1.815"
          y1="1.428"
          y2="26.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1D961" />
          <stop offset="1" stopColor="#CDA146" />
        </linearGradient>
        <clipPath id="coin98-a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Coin98Wallet;
