import { FC, HTMLAttributes } from 'react';

type OneKeyWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const OneKeyWallet: FC<OneKeyWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(-72 , -72)">
        <path
          d="M216 144C216 193.706 193.706 216 144 216C94.2944 216 72 193.706 72 144C72 94.2944 94.2944 72 144 72C193.706 72 216 94.2944 216 144Z"
          fill="#44D62C"
          style={{ fill: '#44D62C', fillOpacity: 1 }}
        />
        <path
          d="M150.505 102.531H130.475L126.961 113.156H138.086V135.538H150.505V102.531Z"
          fill="black"
          style={{ fill: 'black', fillOpacity: 1 }}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.844 162.626C166.844 175.242 156.616 185.469 144 185.469C131.384 185.469 121.157 175.242 121.157 162.626C121.157 150.01 131.384 139.783 144 139.783C156.616 139.783 166.844 150.01 166.844 162.626ZM156.473 162.626C156.473 169.515 150.889 175.099 144 175.099C137.112 175.099 131.528 169.515 131.528 162.626C131.528 155.738 137.112 150.153 144 150.153C150.889 150.153 156.473 155.738 156.473 162.626Z"
          fill="black"
          style={{ fill: 'black', fillOpacity: 1 }}
        />
      </g>
    </svg>
  );
};

export default OneKeyWallet;
