import { FC, HTMLAttributes } from 'react';

type BitgetWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const BitgetWallet: FC<BitgetWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bitget-a)">
        <rect width="256" height="256" fill="#54FFF5" />
        <g filter="url(#bitget-e)">
          <path
            d="M13.4806 198.605C-29.3276 319.043 199.661 285.027 319.507 252.964C442.165 212.259 357.386 32.8269 269.415 28.8558C181.443 24.8847 280.322 111.824 205.595 136.656C130.868 161.487 66.9907 48.0583 13.4806 198.605Z"
            fill="white"
          />
        </g>
        <g filter="url(#bitget-b)">
          <path
            d="M85.5118 -45.8225C63.0562 -107.176 -16.9189 -23.9953 -54.0995 25.2643C-89.5652 78.8479 3.00937 125.152 39.3208 100.037C75.6323 74.9227 7.77448 70.0363 29.3708 37.3785C50.9671 4.72076 113.581 30.8695 85.5118 -45.8225Z"
            fill="#00FFF0"
            fillOpacity="0.67"
          />
        </g>
        <g filter="url(#bitget-c)">
          <path
            d="M96.4796 225.424C65.8502 122.363 -66.0818 176.637 -128.219 216.657C-187.99 264.042 -46.0711 400.348 12.8725 393.376C71.8161 386.403 -34.4118 327.065 1.98702 298.17C38.3858 269.276 134.766 354.249 96.4796 225.424Z"
            fill="#9D81FF"
          />
        </g>
        <g filter="url(#bitget-d)">
          <path
            d="M282.12 -107.353C216.047 -186.031 121.463 -120.97 82.4296 -78.6047C48.2739 -30.6446 224.275 57.2312 273.121 42.1714C321.968 27.1115 206.512 -4.05038 227.297 -33.2879C248.082 -62.5255 364.712 -9.00566 282.12 -107.353Z"
            fill="#4D94FF"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.189 152.836H136.674L87.2086 103.051L137.31 53.2663L150.955 40H105.819L48.3359 97.7773C45.4349 100.689 45.4498 105.402 48.3656 108.299L93.189 152.836ZM119.33 103.168H118.995L119.326 103.164L119.33 103.168ZM119.33 103.168L168.791 152.949L118.69 202.734L105.045 216H150.18L207.664 158.226C210.565 155.314 210.55 150.602 207.634 147.705L162.811 103.168H119.33Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="bitget-e"
          x="-90.2411"
          y="-69.7369"
          width="569.558"
          height="451.431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="49.2308"
            result="effect1_foregroundBlur_2035_1106"
          />
        </filter>
        <filter
          id="bitget-b"
          x="-160.511"
          y="-165.987"
          width="351.596"
          height="371.507"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="49.2308"
            result="effect1_foregroundBlur_2035_1106"
          />
        </filter>
        <filter
          id="bitget-c"
          x="-241.078"
          y="67.642"
          width="444.851"
          height="424.452"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="49.2308"
            result="effect1_foregroundBlur_2035_1106"
          />
        </filter>
        <filter
          id="bitget-d"
          x="-20.3968"
          y="-242.758"
          width="430.191"
          height="385.105"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="49.2308"
            result="effect1_foregroundBlur_2035_1106"
          />
        </filter>
        <clipPath id="bitget-a">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitgetWallet;
