import { FC, HTMLAttributes } from 'react';

type BaiduProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Baidu: FC<BaiduProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill="#000"
      version="1.1"
      viewBox="0 0 96.803 96.803"
      {...props}
    >
      <path d="M36.962 29.912c5.808 0 10.502-6.691 10.502-14.959C47.464 6.682 42.769 0 36.962 0c-5.811 0-10.518 6.682-10.518 14.953 0 8.267 4.706 14.959 10.518 14.959M62.001 30.896c7.775 1.018 12.757-7.275 13.752-13.561 1.02-6.275-4.005-13.576-9.497-14.827-5.515-1.27-12.4 7.561-13.021 13.321-.755 7.046 1 14.073 8.766 15.067M17.422 51.014c10.521-2.261 9.085-14.85 8.777-17.6-.518-4.242-5.505-11.65-12.271-11.059-8.519.759-9.762 13.063-9.762 13.063-1.155 5.7 2.752 17.861 13.256 15.596M81.034 67.841s-12.02-9.297-19.033-19.351c-9.523-14.818-23.039-8.783-27.556-1.257-4.505 7.544-11.507 12.311-12.51 13.576-1.011 1.239-14.516 8.536-11.519 21.861C13.419 95.984 23.95 95.732 23.95 95.732s7.762.758 16.763-1.254c9.02-2.01 16.78.491 16.78.491s21.041 7.056 26.791-6.521c5.755-13.562-3.25-20.607-3.25-20.607M92.791 41.455c0-3.006-2.489-12.066-11.756-12.066-9.286 0-10.513 8.551-10.513 14.591 0 5.768.48 13.81 12.013 13.56 11.519-.257 10.256-13.06 10.256-16.085"></path>
    </svg>
  );
};

export default Baidu;
