import { FC, HTMLAttributes } from 'react';

type ExodusWalletProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const ExodusWallet: FC<ExodusWalletProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 300 300"
      {...props}
    >
      <path
        fill="url(#paint0_linear_1661_295)"
        d="M298.203 83.765 170.449 0v46.833l81.956 53.256-9.642 30.509h-72.314v38.804h72.314l9.642 30.509-81.956 53.256V300l127.754-83.497-20.89-66.369z"
      ></path>
      <path
        fill="url(#paint1_linear_1661_295)"
        d="M59.3 169.402h72.046v-38.804H59.033l-9.374-30.509 81.687-53.256V0L3.593 83.765l20.89 66.369-20.89 66.369L131.614 300v-46.833l-81.955-53.256z"
      ></path>
      <mask
        id="mask0_1661_295"
        width="296"
        height="300"
        x="3"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path
          fill="url(#paint2_linear_1661_295)"
          d="M298.204 83.765 170.45 0v46.833l81.955 53.256-9.642 30.509H170.45v38.804h72.313l9.642 30.509-81.955 53.256V300l127.754-83.497-20.891-66.369z"
        ></path>
        <path
          fill="url(#paint3_linear_1661_295)"
          d="M59.301 169.402h72.046v-38.804H59.033l-9.374-30.509 81.688-53.256V0L3.593 83.765l20.89 66.369-20.89 66.369L131.615 300v-46.833l-81.956-53.256z"
        ></path>
      </mask>
      <g mask="url(#mask0_1661_295)">
        <path
          fill="url(#paint4_linear_1661_295)"
          d="M3.75 0h292.5v300H3.75z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1661_295"
          x1="256.875"
          x2="171.3"
          y1="320.625"
          y2="-32.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B46F9"></stop>
          <stop offset="1" stopColor="#BBFBE0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1661_295"
          x1="256.875"
          x2="171.3"
          y1="320.625"
          y2="-32.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B46F9"></stop>
          <stop offset="1" stopColor="#BBFBE0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_1661_295"
          x1="256.875"
          x2="171.3"
          y1="320.625"
          y2="-32.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B46F9"></stop>
          <stop offset="1" stopColor="#BBFBE0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_1661_295"
          x1="256.875"
          x2="171.3"
          y1="320.625"
          y2="-32.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B46F9"></stop>
          <stop offset="1" stopColor="#BBFBE0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_1661_295"
          x1="22.5"
          x2="170.625"
          y1="67.5"
          y2="178.125"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#8952FF" stopOpacity="0.87"></stop>
          <stop offset="1" stopColor="#DABDFF" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ExodusWallet;
