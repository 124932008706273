import { FC, HTMLAttributes } from 'react';

type UbuntuProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Ubuntu: FC<UbuntuProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size}
      height={size}
      {...props}
    >
      <g fill="#FF5722">
        <circle cx="54.674" cy="256" r="53.333"></circle>
        <circle cx="406.61" cy="458.667" r="53.333"></circle>
        <circle cx="406.61" cy="53.333" r="53.333"></circle>
        <path d="M262.098 147.669a10.666 10.666 0 0 0 11.84 5.013c55.924-14.4 113.089 18.592 128.597 74.219a10.665 10.665 0 0 0 10.261 7.765h87.168c5.891.003 10.669-4.77 10.673-10.661q0-.85-.134-1.691a212.77 212.77 0 0 0-48.512-104.981 10.666 10.666 0 0 0-13.995-1.877A74.27 74.27 0 0 1 406.673 128c-40.78-.145-73.959-32.869-74.667-73.643a10.666 10.666 0 0 0-9.536-10.453 214 214 0 0 0-98.667 12.928c-5.501 2.108-8.251 8.277-6.143 13.778q.302.787.724 1.518zM499.986 277.333h-87.168a10.666 10.666 0 0 0-10.261 7.765c-15.489 55.642-72.67 88.643-128.597 74.219a10.666 10.666 0 0 0-11.84 5.013l-43.605 75.541c-2.947 5.101-1.201 11.625 3.9 14.572q.73.422 1.518.724a210.66 210.66 0 0 0 98.667 12.928 10.667 10.667 0 0 0 9.536-10.453c.472-41.14 34.205-74.109 75.345-73.637a74.5 74.5 0 0 1 40.516 12.538 10.667 10.667 0 0 0 14.016-1.941 212.8 212.8 0 0 0 48.512-104.917c.931-5.817-3.03-11.287-8.847-12.218a11 11 0 0 0-1.692-.134M223.548 330.261c-40.254-41.333-40.254-107.211 0-148.544a10.67 10.67 0 0 0 1.579-12.8l-43.541-75.371a10.67 10.67 0 0 0-7.317-5.163 11.13 11.13 0 0 0-8.661 2.133 213.5 213.5 0 0 0-67.243 96.32 10.67 10.67 0 0 0 3.307 11.712c31.735 25.306 36.947 71.546 11.641 103.281a73.5 73.5 0 0 1-11.641 11.641 10.67 10.67 0 0 0-3.307 11.712 213.5 213.5 0 0 0 67.221 96.235 10.66 10.66 0 0 0 6.741 2.389 11.7 11.7 0 0 0 1.92-.171 10.67 10.67 0 0 0 7.317-5.163l43.541-75.413a10.66 10.66 0 0 0-1.557-12.798"></path>
      </g>
    </svg>
  );
};

export default Ubuntu;
