import { REGEX } from '@formo/shared';
import { ActivityEventSchema } from '@formo/types';
import { DateRange, addDays, addMonths } from '@formo/ui';
import {
  FileSearch2,
  FileText,
  Flag,
  MapPinned,
  SatelliteDish,
  ShieldQuestion,
} from 'lucide-react';
import { ReactNode } from 'react';
import ReactCountryFlag from 'react-country-flag';
import {
  EventActionConnectWallet,
  EventActionDisconnectWallet,
  EventActionIdentify,
  EventActionPageHit,
  EventActionSignature,
  EventActionTransaction,
} from '~/components/icons/analytics';
import {
  Avast,
  Baidu,
  BraveBrowser,
  Chrome,
  Chromium,
  DuckDuckGo,
  Edge,
  Facebook,
  Firefox,
  IE,
  Instagram,
  LinkedIn,
  Opera,
  Safari,
  SamsungInternet,
  Snapchat,
  Twitter,
  WeChat,
  Weibo,
  Yandex,
  Zalo,
} from '~/components/icons/browsers';
import {
  Android,
  IOS,
  Linux,
  MacOS,
  Ubuntu,
  Windows,
} from '~/components/icons/os';
import Harmony from '~/components/icons/os/Harmony';
import Solaris from '~/components/icons/os/Solaris';
import countryNameJSON from '~/json/country.name.json';

import { getChain } from '../chains';
import { WALLET_MAP } from '../wallets';

// #region Functions
const renderWalletName = (providerName?: string): string => {
  if (!providerName) return '';

  return ` (${providerName})`;
};

export const renderWalletProviderIcon = (rdns?: string): ReactNode => {
  if (!rdns) return <EventActionIdentify />;

  if (!(rdns in WALLET_MAP)) return <ShieldQuestion size={16} />;

  return (
    <span className="[&>svg]:w-4 [&>svg]:h-4 overflow-hidden rounded-full">
      {WALLET_MAP[rdns as keyof typeof WALLET_MAP]?.icon}
    </span>
  );
};

export const renderWalletProviderName = (rdns?: string): ReactNode => {
  if (!rdns) return 'Unsupport wallet';
  if (!(rdns in WALLET_MAP)) return <>{rdns}</>;
  const name = WALLET_MAP[rdns as keyof typeof WALLET_MAP]?.name;
  return <>{name}</>;
};

export const renderChainName = (chainId?: string | number): string => {
  if (!chainId) return '';

  const chain = Number(chainId);
  if (!chain) return ` (ChainID: ${chainId})`;
  const extractedChain = getChain(chain);
  if (!extractedChain) return ` (ChainID: ${chainId})`;
  return ` (${extractedChain.name})`;
};

// #region ACTIONS
export enum EVENT_ACTION {
  PAGE_HIT = 'page_hit',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  SIGNATURE = 'signature',
  CHAIN_CHANGED = 'chain_changed',
  IDENTIFY = 'identify',
  DECODED_LOG = 'decoded_log',
  TRANSACTION = 'transaction',
}

export const EVENT_ACTION_KEYS = Object.values(EVENT_ACTION);
export const EVENT_ACTION_TEXT_SHORT: Record<EVENT_ACTION, string> = {
  [EVENT_ACTION.PAGE_HIT]: 'Page view',
  [EVENT_ACTION.CONNECT]: 'Connect wallet',
  [EVENT_ACTION.DISCONNECT]: 'Disconnect wallet',
  [EVENT_ACTION.SIGNATURE]: 'Signature',
  [EVENT_ACTION.TRANSACTION]: 'Transaction',
  [EVENT_ACTION.CHAIN_CHANGED]: 'Changed chain',
  [EVENT_ACTION.IDENTIFY]: 'Identify',
  [EVENT_ACTION.DECODED_LOG]: 'Onchain event',
};

export const EVENT_ACTION_TEXT: Record<
  EVENT_ACTION | string,
  (record: Partial<ActivityEventSchema>) => ReactNode
> = {
  [EVENT_ACTION.PAGE_HIT]: (record) => {
    const href = record?.event_payload?.href?.split(/https?:\/\//)?.[1];
    if (REGEX.BASE_URL.test(href as string)) return 'Viewed homepage';
    else return 'Viewed ' + (href || '');
  },

  [EVENT_ACTION.CONNECT]: (record) =>
    `Connected wallet${renderChainName(record?.event_payload?.chain_id)}`,
  [EVENT_ACTION.DISCONNECT]: (record) =>
    `Disconnected wallet${renderChainName(record?.event_payload?.chain_id)}`,
  [EVENT_ACTION.SIGNATURE]: (record) =>
    `Signature ${record?.event_payload?.status}${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.CHAIN_CHANGED]: (record) =>
    `Changed chain${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.TRANSACTION]: (record) =>
    `Transaction ${record?.event_payload?.status}${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.IDENTIFY]: (record) =>
    `Identified wallet${renderWalletName(record?.event_payload?.provider_name || record?.event_payload?.name)}`,
  [EVENT_ACTION.DECODED_LOG]: () => 'Onchain event',
};

export const EVENT_ACTION_ICON: Record<
  EVENT_ACTION | string,
  (record?: Partial<ActivityEventSchema>) => ReactNode
> = {
  [EVENT_ACTION.PAGE_HIT]: () => <EventActionPageHit />,
  [EVENT_ACTION.CONNECT]: () => <EventActionConnectWallet />,
  [EVENT_ACTION.DISCONNECT]: () => <EventActionDisconnectWallet />,
  [EVENT_ACTION.SIGNATURE]: () => <EventActionSignature />,
  [EVENT_ACTION.CHAIN_CHANGED]: () => <EventActionPageHit />,
  [EVENT_ACTION.TRANSACTION]: () => <EventActionTransaction />,
  [EVENT_ACTION.IDENTIFY]: (record) =>
    renderWalletProviderIcon(record?.event_payload?.rdns),
  [EVENT_ACTION.DECODED_LOG]: () => <EventActionPageHit />,
};

// #region DEVICES
export enum EVENT_DEVICE {
  DESKTOP = 'desktop',
  MOBILE_ANDROID = 'mobile-android',
  MOBILE_IOS = 'mobile-ios',
  BOT = 'bot',
}

export const EVENT_DEVICE_KEYS = Object.values(EVENT_DEVICE);
export const EVENT_DEVICE_TEXT_SHORT: Record<EVENT_DEVICE, string> = {
  [EVENT_DEVICE.DESKTOP]: 'Desktop',
  [EVENT_DEVICE.MOBILE_ANDROID]: 'Android',
  [EVENT_DEVICE.MOBILE_IOS]: 'iOS',
  [EVENT_DEVICE.BOT]: 'Bot',
};
export const EVENT_DEVICE_TEXT: Record<EVENT_DEVICE | string, React.ReactNode> =
  {
    [EVENT_DEVICE.DESKTOP]: <span>Desktop</span>,
    [EVENT_DEVICE.MOBILE_ANDROID]: <span>Android</span>,
    [EVENT_DEVICE.MOBILE_IOS]: <span>iOS</span>,
    [EVENT_DEVICE.BOT]: <span className="text-content-danger">Bot</span>,
  };

export const EVENT_DEVICE_ICON: Record<EVENT_DEVICE | string, React.ReactNode> =
  {
    [EVENT_DEVICE.DESKTOP]: (
      <span className="leading-[16px] text-base">💻</span>
    ),
    [EVENT_DEVICE.MOBILE_ANDROID]: (
      <span className="leading-[16px] text-base">📱</span>
    ),
    [EVENT_DEVICE.MOBILE_IOS]: (
      <span className="leading-[16px] text-base">📱</span>
    ),
    [EVENT_DEVICE.BOT]: (
      <span className="text-content-danger leading-[16px] text-base">🤖</span>
    ),
  };

// #region BROWSERS
export enum EVENT_BROWSER {
  BRAVE = 'brave',
  DUCKDUCKGO = 'duckduckgo',
  EDGE = 'edge',
  FIREFOX = 'firefox',
  CHROME = 'chrome',
  OPERA = 'opera',
  SAFARI = 'safari',
  IE = 'ie',
  SAMSUNG_INTERNET = 'samsung_internet',
  BAIDU = 'baidu',
  WECHAT = 'wechat',
  WEIBO = 'weibo',
  YANDEX = 'yandex',
  CHROMIUM = 'chromium',
  ZALO = 'zalo',
  FACEBOOK_WEBVIEW = 'facebook_webview',
  TWITTER_WEBVIEW = 'twitter_webview',
  INSTAGRAM_WEBVIEW = 'instagram_webview',
  SNAPCHAT_WEBVIEW = 'snapchat_webview',
  LINKEDIN_WEBVIEW = 'linkedin_webview',
  AVAST = 'avast',
}

export const EVENT_BROWSER_KEYS = Object.values(EVENT_BROWSER);
export const EVENT_BROWSER_TEXT: Record<EVENT_BROWSER | string, string> = {
  [EVENT_BROWSER.BRAVE]: 'Brave',
  [EVENT_BROWSER.DUCKDUCKGO]: 'DuckDuckGo',
  [EVENT_BROWSER.EDGE]: 'Edge',
  [EVENT_BROWSER.FIREFOX]: 'Firefox',
  [EVENT_BROWSER.CHROME]: 'Chrome',
  [EVENT_BROWSER.OPERA]: 'Opera',
  [EVENT_BROWSER.SAFARI]: 'Safari',
  [EVENT_BROWSER.IE]: 'Internet Explorer',
  [EVENT_BROWSER.SAMSUNG_INTERNET]: 'Samsung Internet',
  [EVENT_BROWSER.BAIDU]: 'Baidu',
  [EVENT_BROWSER.WECHAT]: 'WeChat',
  [EVENT_BROWSER.WEIBO]: 'Weibo',
  [EVENT_BROWSER.YANDEX]: 'Yandex',
  [EVENT_BROWSER.CHROMIUM]: 'Chromium',
  [EVENT_BROWSER.ZALO]: 'Zalo',
  [EVENT_BROWSER.FACEBOOK_WEBVIEW]: 'Facebook Webview',
  [EVENT_BROWSER.TWITTER_WEBVIEW]: 'Twitter Webview',
  [EVENT_BROWSER.INSTAGRAM_WEBVIEW]: 'Instagram Webview',
  [EVENT_BROWSER.SNAPCHAT_WEBVIEW]: 'Snapchat Webview',
  [EVENT_BROWSER.LINKEDIN_WEBVIEW]: 'LinkedIn Webview',
  [EVENT_BROWSER.AVAST]: 'Avast',
};

export const EVENT_BROWSER_ICON: Record<
  EVENT_BROWSER | string,
  React.ReactNode
> = {
  [EVENT_BROWSER.BRAVE]: <BraveBrowser />,
  [EVENT_BROWSER.DUCKDUCKGO]: <DuckDuckGo />,
  [EVENT_BROWSER.EDGE]: <Edge />,
  [EVENT_BROWSER.FIREFOX]: <Firefox />,
  [EVENT_BROWSER.CHROME]: <Chrome />,
  [EVENT_BROWSER.OPERA]: <Opera />,
  [EVENT_BROWSER.SAFARI]: <Safari />,
  [EVENT_BROWSER.IE]: <IE />,
  [EVENT_BROWSER.SAMSUNG_INTERNET]: <SamsungInternet />,
  [EVENT_BROWSER.BAIDU]: <Baidu />,
  [EVENT_BROWSER.WECHAT]: <WeChat />,
  [EVENT_BROWSER.WEIBO]: <Weibo />,
  [EVENT_BROWSER.YANDEX]: <Yandex />,
  [EVENT_BROWSER.CHROMIUM]: <Chromium />,
  [EVENT_BROWSER.ZALO]: <Zalo />,
  [EVENT_BROWSER.FACEBOOK_WEBVIEW]: <Facebook />,
  [EVENT_BROWSER.TWITTER_WEBVIEW]: <Twitter />,
  [EVENT_BROWSER.INSTAGRAM_WEBVIEW]: <Instagram />,
  [EVENT_BROWSER.SNAPCHAT_WEBVIEW]: <Snapchat />,
  [EVENT_BROWSER.LINKEDIN_WEBVIEW]: <LinkedIn />,
  [EVENT_BROWSER.AVAST]: <Avast />,
};

// #region OS
export enum EVENT_OS {
  ANDROID = 'android',
  LINUX = 'linux',
  MACOS = 'macos',
  WINDOWS = 'windows',
  IOS = 'ios',
  UBUNTU = 'ubuntu',
  SOLARIS = 'solaris',
  HARMONY = 'harmony',
  CHROMEOS = 'chromeos',
}

export const EVENT_OS_KEYS = Object.values(EVENT_OS);
export const EVENT_OS_TEXT: Record<EVENT_OS | string, string> = {
  [EVENT_OS.ANDROID]: 'Android',
  [EVENT_OS.LINUX]: 'Linux',
  [EVENT_OS.MACOS]: 'Macintosh',
  [EVENT_OS.WINDOWS]: 'Windows',
  [EVENT_OS.IOS]: 'iOS',
  [EVENT_OS.UBUNTU]: 'Ubuntu',
  [EVENT_OS.SOLARIS]: 'Solaris',
  [EVENT_OS.HARMONY]: 'Harmony',
  [EVENT_OS.CHROMEOS]: 'ChromeOS',
};

export const EVENT_OS_ICON: Record<EVENT_OS | string, React.ReactNode> = {
  [EVENT_OS.ANDROID]: <Android />,
  [EVENT_OS.LINUX]: <Linux />,
  [EVENT_OS.MACOS]: <MacOS />,
  [EVENT_OS.WINDOWS]: <Windows />,
  [EVENT_OS.IOS]: <IOS />,
  [EVENT_OS.UBUNTU]: <Ubuntu />,
  [EVENT_OS.SOLARIS]: <Solaris />,
  [EVENT_OS.HARMONY]: <Harmony />,
  [EVENT_OS.CHROMEOS]: <Chrome />,
};

// #region COUNTRIES
export const COUNTRY_CODES = Object.keys(countryNameJSON);
export const EVENT_COUNTRY_TEXT: Record<string, string> = countryNameJSON;

export const EVENT_COUNTRY_ICON = ({
  code,
  size = 18,
}: {
  code: string;
  size?: number;
}) => {
  return (
    <ReactCountryFlag
      countryCode={code}
      svg
      width={size}
      height={size}
      style={{
        width: size + 'px',
        height: size + 'px',
      }}
      className="rounded-full object-cover border border-content-border"
    />
  );
};

// #region UTM
export enum EVENT_UTM {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
}
export const EVENT_UTM_CODES = Object.values(EVENT_UTM);
export const EVENT_UTM_TEXT: Record<EVENT_UTM | string, string> = {
  [EVENT_UTM.UTM_SOURCE]: 'UTM Source',
  [EVENT_UTM.UTM_MEDIUM]: 'UTM Medium',
  [EVENT_UTM.UTM_CAMPAIGN]: 'UTM Campaign',
  [EVENT_UTM.UTM_CONTENT]: 'UTM Content',
  [EVENT_UTM.UTM_TERM]: 'UTM Term',
};

export const EVENT_UTM_ICON: Record<EVENT_OS | string, React.ReactNode> = {
  [EVENT_UTM.UTM_SOURCE]: <MapPinned size={16} />,
  [EVENT_UTM.UTM_MEDIUM]: <SatelliteDish size={16} />,
  [EVENT_UTM.UTM_CAMPAIGN]: <Flag size={16} />,
  [EVENT_UTM.UTM_CONTENT]: <FileText size={16} />,
  [EVENT_UTM.UTM_TERM]: <FileSearch2 size={16} />,
};

// #region DATE RANGE FILTER
export const DATE_RANGE_FILTER: {
  label: string;
  date: (...args: any) => DateRange;
}[] = [
  {
    label: '7D',
    date: () => ({
      from: addDays(new Date(), -7),
      to: new Date(),
    }),
  },
  {
    label: '30D',
    date: () => ({
      from: addDays(new Date(), -30),
      to: new Date(),
    }),
  },
  {
    label: '3M',
    date: () => ({
      from: addMonths(new Date(), -3),
      to: new Date(),
    }),
  },
  {
    label: '6M',
    date: () => ({
      from: addMonths(new Date(), -6),
      to: new Date(),
    }),
  },
  {
    label: '12M',
    date: () => ({
      from: addMonths(new Date(), -12),
      to: new Date(),
    }),
  },
  {
    label: 'All Time',
    date: ({ createdAt }: { createdAt?: Date }) => {
      return {
        from: createdAt || new Date(),
        to: new Date(),
      };
    },
  },
];

export const PROJECT_READ_TOKEN_KEY_NAME = (projectId: string) =>
  projectId + '_rtk';

export const LAST_ACTIVE_PROJECT_KEY_NAME = 'last_active_project';

export enum CHART_GROWTH_TREND {
  UP = 'up',
  DOWN = 'down',
}
