import { FC, HTMLAttributes } from 'react';

type ChromeProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const Chrome: FC<ChromeProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="fi_888846"
      x="0"
      y="0"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 512.023 512.023"
      {...props}
    >
      <path
        fill="#F44336"
        d="M493.248 160.021H256.021c-53.019-.052-96.042 42.886-96.095 95.905a96 96 0 0 0 12.255 47.028L53.568 99.434c86.393-111.815 247.073-132.423 358.888-46.03a255.85 255.85 0 0 1 80.792 106.617"
      ></path>
      <path
        fill="#FFC107"
        d="M512.021 256.021c-.118 141.336-114.664 255.882-256 256a228 228 0 0 1-32-2.133l115.2-205.867c26.403-45.964 10.664-104.625-35.2-131.2a95.1 95.1 0 0 0-47.787-12.8h237.013a252.4 252.4 0 0 1 18.774 96"
      ></path>
      <path
        fill="#4CAF50"
        d="m339.221 304.021-115.2 205.867h-.213C83.62 492.176-15.666 364.172 2.046 223.984a255.85 255.85 0 0 1 51.522-124.55l118.613 203.52.64 1.067c26.451 45.95 85.143 61.757 131.093 35.307a96 96 0 0 0 35.307-35.307"
      ></path>
      <path
        fill="#F44336"
        d="M339.221 304.021c-26.451 45.95-85.143 61.757-131.093 35.307a96 96 0 0 1-35.307-35.307l-.64-1.067c-25.921-46.251-9.439-104.758 36.812-130.679a96 96 0 0 1 47.028-12.255h.213a95.1 95.1 0 0 1 47.787 12.8c45.865 26.576 61.603 85.237 35.2 131.201"
      ></path>
      <path
        fill="#2196F3"
        d="M339.221 304.021c-26.451 45.95-85.143 61.757-131.093 35.307a96 96 0 0 1-35.307-35.307l-.64-1.067c-25.921-46.251-9.439-104.758 36.812-130.679a96 96 0 0 1 47.028-12.255h.213a95.1 95.1 0 0 1 47.787 12.8c45.865 26.576 61.603 85.237 35.2 131.201"
      ></path>
      <path
        fill="#FAFAFA"
        d="M255.659 362.688a105.9 105.9 0 0 1-52.971-14.208 107.06 107.06 0 0 1-39.104-39.125c-29.455-51.018-11.975-116.254 39.043-145.709a106.7 106.7 0 0 1 53.395-14.291c58.91-.046 106.704 47.674 106.749 106.584a106.66 106.66 0 0 1-14.291 53.416 107.27 107.27 0 0 1-92.821 53.333m.554-192a85.1 85.1 0 0 0-74.219 42.667c-23.564 40.86-9.543 93.087 31.317 116.651s93.087 9.543 116.651-31.317c23.564-40.814 9.58-93.003-31.234-116.568a85.34 85.34 0 0 0-42.494-11.432h-.021z"
      ></path>
    </svg>
  );
};

export default Chrome;
