export const saveSessionStorage = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log('[ERROR] Failed to save to sessionStorage', error);
  }
};

export const loadSessionStorage = (key: string) => {
  try {
    const data = JSON.parse(sessionStorage.getItem(key) ?? '{}');
    return data;
  } catch (error) {
    console.log('[ERROR] Failed to load from sessionStorage', error);
    return {};
  }
};

export { default as formoSessionStorage } from './session-storage';
export { default as formoLocalStorage } from './local-storage';
