import { FC, HTMLAttributes } from 'react';

type MacOSProps = HTMLAttributes<SVGElement> & {
  size?: number;
};

const MacOS: FC<MacOSProps> = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      {...props}
    >
      <path d="M2.1 6.96v35.98H48V6.96H2.1m25.58 2H46v31.98H29.01c-.27-.95-.47-1.92-.61-2.89-.11-.77-.17-1.52-.21-2.24v-.01q.01-.002.02-.005c.003.064-.002.121 0 .185.66-.04 1.32-.09 1.98-.17 3.52-.41 6.99-1.34 10.24-2.77l-.8-1.83a33.9 33.9 0 0 1-9.42 2.58c-.66.08-1.33.15-2 .19q.002.031 0 .063l-.01-.002v-.021c0-.02 0-.03.01-.04-.01-.07 0-.13 0-.19a22.7 22.7 0 0 1 .85-5.01c.01-.01.01-.03.02-.05.13-.46.28-.89.42-1.29l.49-1.34h-8.74a42.9 42.9 0 0 1 3.41-11.62c.87-1.91 1.87-3.76 3.02-5.52m-15.77 5.013h2.037l.004 5.058-2.053-.015zm22.1.037v4.97h1.865v-4.97zM10.365 31.15a33.8 33.8 0 0 0 15.813 2.83c-.03.64-.03 1.31 0 2-.712.05-1.436.07-2.147.07-.37 0-.73-.01-1.101-.02a35.8 35.8 0 0 1-13.377-3.05z"></path>
    </svg>
  );
};

export default MacOS;
